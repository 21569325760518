import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function LectureForm() {
    const [step, setStep] = React.useState(1);

    function handleStep(value) {
        setStep(value);
    };

    return (
        <>
            <Layout user="student">
                <Gap />

                <section className="page-content">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end">
                                        <Link to="/lecture/details" className="btn btn-outline-primary me-3 px-4">Save Draft</Link>
                                        <Link to="/lecture/details" className="btn btn-primary px-4">Publish</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-3">
                                    <div className="mb-2">Steps to follow</div>

                                    <ul className="list-group rounded-0 text-center">
                                        <li className={`list-group-item cursor-pointer ${step === 1 ? 'active' : ''}`} onClick={() => handleStep('1') }>Add Discriptions</li>
                                        <li className={`list-group-item cursor-pointer ${step === 2 ? 'active' : ''}`} onClick={() => handleStep('2') }>Upload Concept</li>
                                        <li className={`list-group-item cursor-pointer ${step === 3 ? 'active' : ''}`} onClick={() => handleStep('3') }>Outline Your Concept</li>
                                        <li className={`list-group-item cursor-pointer ${step === 4 ? 'active' : ''}`} onClick={() => handleStep('4') }>Select Price</li>
                                        <li className={`list-group-item cursor-pointer ${step === 5 ? 'active' : ''}`} onClick={() => handleStep('5') }>Connet with Students</li>
                                        <li className={`list-group-item cursor-pointer ${step === 6 ? 'active' : ''}`} onClick={() => handleStep('6') }>Create Quizes</li>
                                    </ul>
                                </div>

                                <div className="col-9">
                                    <div className="tab-content bg-white shadow py-4 px-5" style={{ marginTop: 32 }}>
                                        {/* Step - 1: Add Discriptions */}
                                        <div className={`tab-pane fade ${step === 1 ? 'show active' : ''}`} id="step-1">
                                            <div className="row">
                                                <div className="col-5 mb-3">
                                                    <label htmlFor="subject" className="form-label">Select Subject</label>
                                                    <select className="form-control form-select" name="subject" id="subject">
                                                        <option value=""></option>
                                                        <option value="Queatum Engineering">Queatum Engineering</option>
                                                        <option value="Machine Learning">Machine Learning</option>
                                                        <option value="Quantum Physics">Quantum Physics</option>
                                                        <option value="Math">Math</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="lecture_title" className="form-label">Lecture Title</label>
                                                    <input type="text" className="form-control" name="lecture_title" id="lecture_title" />
                                                </div>

                                                <div className="col-12 mb-3">
                                                    <label htmlFor="discription" className="form-label">Lecture Discription</label>
                                                    <textarea className="form-control" name="discription" id="discription" rows="5"></textarea>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6">
                                                    <label htmlFor="tags" className="form-label">Select Tags</label>
                                                    <select className="form-control form-select" name="tags" id="tags">
                                                        <option value=""></option>
                                                        <option value="Tag 1">Tag 1</option>
                                                        <option value="Tag 2">Tag 2</option>
                                                        <option value="Tag 3">Tag 3</option>
                                                        <option value="Tag 4">Tag 4</option>
                                                    </select>
                                                </div>

                                                <div className="col-6 text-end">
                                                    <Gap height="32px" />
                                                    <button type="button" className="btn btn-primary btn-sm px-4" onClick={() => handleStep('3') }>Next</button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step - 2: Upload Concept */}
                                        <div className={`tab-pane fade ${step === 2 ? 'show active' : ''}`} id="step-2">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <div className="form-label">Select Lecture Type</div>
                                                    <button type="button" className="btn btn-outline-muted me-3 my-4 p-1 px-5 active">Video</button>
                                                    <button type="button" className="btn btn-outline-muted me-3 my-4 p-1 px-5">Notebook</button>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mt-3 form-label">Upload Video</div>
                                                    <label htmlFor="video" className="w-100">
                                                        <div className="border d-flex justify-content-center align-items-center cursor-pointer py-5" id="upload">
                                                            <img src="/assets/images/icon/30.svg" width="50" height="50" alt="Upload" loading="lazy" />
                                                            <input type="file" className="d-none" name="video" id="video" accept="video/*" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row align-items-end mb-5">
                                                <div className="col-7">
                                                    <div className="mt-3 form-label">Upload Cover Image</div>
                                                    <label htmlFor="cover" className="w-50">
                                                        <div className="border d-flex justify-content-center align-items-center cursor-pointer py-5" id="upload">
                                                            <img src="/assets/images/icon/30.svg" width="30" height="30" alt="Upload" loading="lazy" />
                                                            <input type="file" className="d-none" name="cover" id="cover" accept="image/*" />
                                                        </div>
                                                    </label>
                                                </div>

                                                <div className="col-5 text-end">
                                                    <button type="button" className="btn btn-primary btn-sm px-4" onClick={() => handleStep('2') }>Next</button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step - 3: Outline Your Concept */}
                                        <div className={`tab-pane fade ${step === 3 ? 'show active' : ''}`} id="step-3">
                                            <div className="row">
                                                <div className="form-label mb-3">Out Line Your LEctures Into Individual Concepts</div>

                                                <div className="col-12 d-flex">
                                                    <div className="form-control rounded-0 fs-1 d-flex justify-content-center align-items-center text-muted" style={{ width: 100 }}>1</div>

                                                    <div className="w-100">
                                                        <div className="col-12">
                                                            <input type="text" className="form-control rounded-0 border-bottom-0" name="concept_title" id="concept_title" placeholder="Concept Title" />
                                                        </div>

                                                        <div className="col-12">
                                                            <textarea className="form-control rounded-0" name="concept_discription" id="concept_discription" placeholder="Concept Discription" rows="5"></textarea>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-center text-center my-4">
                                                    <div className="app-block-40 cursor-pointer rounded-circle bg-primary-300 text-white fs-4 pt-1">+</div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-12 text-end">
                                                    <button type="button" className="btn btn-primary btn-sm px-4" onClick={() => handleStep('4') }>Next</button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step - 4: Select Price */}
                                        <div className={`tab-pane fade ${step === 4 ? 'show active' : ''}`} id="step-4">
                                            <div className="row">
                                                <div className="col-4 mb-3">
                                                    <label htmlFor="currency" className="form-label mb-3">Set Your Lectures Price</label>
                                                    <select className="form-control form-select" name="currency" id="currency">
                                                        <option value="USD">USD</option>
                                                    </select>
                                                </div>

                                                <div className="col-4 mb-3">
                                                    <label htmlFor="amount" className="form-label mb-3 opacity-0">Amount</label>
                                                    <input type="text" className="form-control" name="amount" id="amount" placeholder="Enter Amount" />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-12 text-end">
                                                    <button type="button" className="btn btn-primary btn-sm px-4" onClick={() => handleStep('5') }>Next</button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step - 5: Connet with Students */}
                                        <div className={`tab-pane fade ${step === 5 ? 'show active' : ''}`} id="step-5">
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="colab_link" className="form-label mb-3">Colab Link</label>
                                                    <input type="text" className="form-control" name="colab_link" id="colab_link" placeholder="https://colab.research.google.com" />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 text-end">
                                                    <button type="button" className="btn btn-primary btn-sm px-4" onClick={() => handleStep('6') }>Next</button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step - 6: Create Quizes */}
                                        <div className={`tab-pane fade ${step === 6 ? 'show active' : ''}`} id="step-6">
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <div className="text-center position-relative mb-3">
                                                        <span className="w-100 text-start app-text-xs position-absolute top-50 start-0 translate-middle-y">
                                                            <img src="/assets/images/icon/35.svg" className="me-1" width="10" height="10" alt="About" loading="lazy" />
                                                            Create at least 10 quizes.
                                                        </span>

                                                        <span className="w-100">Create Quizes</span>
                                                    </div>

                                                    <div className="border rounded-3 p-3">
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <input type="text" className="form-control ps-0 rounded-0 border-top-0 border-x-0 border-bottom" name="question" id="question" placeholder="Question" />
                                                            </div>

                                                            <div className="col-4">
                                                                <select className="form-control form-select" name="short_answer" id="short_answer">
                                                                    <option value="">Short Answer</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4 mt-2">
                                                                <div className="form-check m-0 mb-2">
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" name="option_ans_1" />
                                                                    <input type="text" className="form-control ps-0 rounded-0 border-top-0 border-x-0 border-bottom border-dashed" name="option_2" placeholder="Option 1" />
                                                                </div>

                                                                <div className="form-check m-0 mb-2">
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" name="option_ans_2" />
                                                                    <input type="text" className="form-control ps-0 rounded-0 border-top-0 border-x-0 border-bottom border-dashed" name="option_2" placeholder="Option 2" />
                                                                </div>

                                                                <div className="form-check m-0 mb-2">
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" name="option_ans_3" />
                                                                    <input type="text" className="form-control ps-0 rounded-0 border-top-0 border-x-0 border-bottom border-dashed" name="option_3" placeholder="Option 3" />
                                                                </div>

                                                                <div className="form-check m-0 mb-2">
                                                                    <input className="form-check-input mt-2 me-2" type="checkbox" name="option_ans_4" />
                                                                    <input type="text" className="form-control ps-0 rounded-0 border-top-0 border-x-0 border-bottom border-dashed" name="option_4" placeholder="Option 4" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div>
                                                            <div className="col-12 text-center my-3">
                                                                <span className="w-100 text-start app-text-xs">
                                                                    <img src="/assets/images/icon/35.svg" className="me-1" width="10" height="10" alt="About" loading="lazy" />
                                                                    Select the right answer.
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex justify-content-center text-center my-4">
                                                            <div className="app-block-40 cursor-pointer rounded-circle bg-primary-300 text-white fs-4 pt-1">+</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 text-end">
                                                    <Link to="/lecture/details" className="btn btn-primary btn-sm px-4">Publish</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default LectureForm;