import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function Dashboard() {
    let students = [
        {
            'name': 'Daniel Shepherd',
            'progress': 70,
            'status': 'Certified',
        },
        {
            'name': 'Joline Wixon',
            'progress': 20,
            'status': 'Joined',
        },
        {
            'name': 'Shelba Sidwell',
            'progress': 50,
            'status': 'Completed',
        },
        {
            'name': 'Lorenza Kerby',
            'progress': 90,
            'status': 'Certified',
        },
        {
            'name': 'Shizuko Riendeau',
            'progress': 40,
            'status': 'Completed',
        },
        {
            'name': 'Regine Coffield',
            'progress': 45,
            'status': 'Certified',
        },
        {
            'name': 'Karyn Beavers',
            'progress': 80,
            'status': 'Certified',
        },
        {
            'name': 'Kaycee Ross',
            'progress': 40,
            'status': 'Joined',
        },
        {
            'name': 'Yuri Brodsky',
            'progress': 30,
            'status': 'Certified',
        },
        {
            'name': 'Renita Grimley',
            'progress': 70,
            'status': 'Certified',
        },
        {
            'name': 'Renita Grimley',
            'progress': 80,
            'status': 'Certified',
        },
        {
            'name': 'Tameka Varn',
            'progress': 100,
            'status': 'Certified',
        },
        {
            'name': 'Hae Renwick',
            'progress': 60,
            'status': 'Certified',
        },
        {
            'name': 'Herlinda Donald',
            'progress': 60,
            'status': 'Certified',
        },
    ];

    let statusColor = {'Certified': '#26A3EA', 'Joined': '#32C340', 'Completed': '#9426EA'};

    return (
        <>
            <Gap height="40px" />

            <Layout user="mentor">
                <section className="dashboard-section">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <div className="fs-5 fw-bold">Career Paths</div>
                                </div>

                                <div className="col d-flex justify-content-end">
                                    <div className="d-flex align-items-center bg-white rounded shadow py-2 px-3 position-relative" style={{ width: 220 }}>
                                        <div className="d-flex justify-content-center text-center pe-3">
                                            <div className="app-block-40 cursor-pointer rounded-circle bg-primary-300 text-white fs-4 pt-1">+</div>
                                        </div>

                                        <div>
                                            <div className="app-text-md text-primary fw-bold">Create New Path</div>
                                        </div>

                                        <Link to="/career/form" className="stretched-link"></Link>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav app-tabs mt-3">
                                        <li className="nav-item active">
                                            <a href="#active" className="nav-link active" data-bs-toggle="tab">Active</a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#active" className="nav-link" data-bs-toggle="tab">Unpublished</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="active">
                                            <div className="row mt-5">
                                                <div className="splide course-splide">
                                                    <div className="splide__arrows">
                                                        <button className="splide__arrow splide__arrow--prev"><i className="bi bi-chevron-left"></i></button>
                                                        <button className="splide__arrow splide__arrow--next"><i className="bi bi-chevron-right"></i></button>
                                                    </div>

                                                    <div className="splide__track pb-1">
                                                        <ul className="splide__list">
                                                            {[...Array(6)].map((value, index) =>
                                                                <li className="splide__slide" key={'career-key-'+ index}>
                                                                    <div className="editable-card rounded py-1 border-primary">
                                                                        <Link to="#" className="editable-card-icon">
                                                                            <img src="/assets/images/icon/36.svg" width="10" height="10" alt="Edit" loading="lazy" />
                                                                        </Link>

                                                                        <div className="p-2">
                                                                            <div className="text-center">
                                                                                <div className="app-text-md text-center editable-card-title">Become a Font-end<br />Developer at spaceX</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dashboard-section">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <div className="bg-light-300 py-2 px-3">
                                        <div className="fs-5 fw-bold mb-1">2031 Students</div>
                                        <div className="app-text-xs">Created at : 12/03/2021</div>
                                    </div>

                                    <div className="bg-white shadow pt-2">
                                        <div className="py-2 px-3">
                                            <span className="app-text-md">Select Type</span>
                                            <button type="button" className="btn btn-outline-primary active app-text-sm py-0 px-4 ms-5" style={{ borderRadius: 30 }}>All</button>
                                            <button type="button" className="btn btn-outline-primary app-text-sm py-0 px-4 ms-3" style={{ borderRadius: 30 }}>Joined</button>
                                            <button type="button" className="btn btn-outline-primary app-text-sm py-0 px-4 ms-3" style={{ borderRadius: 30 }}>Completed</button>
                                            <button type="button" className="btn btn-outline-primary app-text-sm py-0 px-4 ms-3" style={{ borderRadius: 30 }}>Certified</button>
                                        </div>

                                        <div className="mt-4">
                                            <div className="table-responsive">
                                                <table className="table align-middle">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" width="5%">SL</th>
                                                            <th className="text-center">Student Name</th>
                                                            <th className="text-center">Progress Bar</th>
                                                            <th className="text-center">Quizes</th>
                                                            <th className="text-center">Status</th>
                                                            <th className="text-end">Profile</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {students.map((student, index) =>
                                                            <tr key={'student-key-'+ index}>
                                                                <td className="text-center">{ index + 1 }</td>
                                                                <td className="text-center">{student.name}</td>
                                                                <td className="text-center">
                                                                    <div className="d-inline-block" style={{ width: 150 }}>
                                                                        <div className="progress progress-sm">
                                                                            <div className="progress-bar" role="progressbar" style={{ width: student.progress + '%' }}></div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <Link to="/course/quize-apply">
                                                                        <img src="/assets/images/icon/37.svg" width="20" height="20" alt="Quize" loading="lazy" />
                                                                    </Link>
                                                                </td>
                                                                <td className="text-center" style={{ color: statusColor[student.status] }}>{student.status}</td>
                                                                <td className="text-end">
                                                                    <Link to="/student/profile" className="btn btn-outline-primary rounded-1 border-primary-300 py-2">View Profile</Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Link to="#" className="app-block-sm rounded-circle bg-primary-300 d-flex justify-content-center align-items-center">
                                            <img src="/assets/images/icon/38.svg" width="15" height="15" alt="Prev" loading="lazy" />
                                        </Link>

                                        <div className="mx-3">
                                            <span>1</span> - <span className="text-muted">7</span>
                                        </div>

                                        <Link to="#" className="app-block-sm rounded-circle bg-primary d-flex justify-content-center align-items-center">
                                            <img src="/assets/images/icon/39.svg" width="15" height="15" alt="Next" loading="lazy" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Dashboard;