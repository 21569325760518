import Header from './Header';
import SideMenu from './SideMenu';
import Footer from './Footer';

function Layout(props) {
    let isHeader = props.header ?? true;
    let isFooter = props.footer ?? true;

    return (
        <>
            {isHeader ? <Header user={props.user} /> : null}

            <SideMenu user={props.user} />

            <main className="main" style={{ marginTop: isHeader ? '70px' : '0px' }}>
                {props.children}
            </main>

            {isFooter ? <Footer /> : null}
        </>
    );
}

export default Layout;