import React from 'react';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function BidsForm() {
    const delimiters = [188, 13];

    const [tags, setTags] = React.useState([
        { id: 'Tag 1', text: 'Tag 1' },
        { id: 'Tag 2', text: 'Tag 2' },
        { id: 'Tag 3', text: 'Tag 3' },
    ]);

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    return (
        <>
            <Layout user="student">
                <Gap />

                <section className="page-content">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="bg-white border py-4 px-5 mt-3">
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <label htmlFor="subject" className="form-label">Select Subject</label>
                                                <select className="form-control form-select" name="subject" id="subject">
                                                    <option value=""></option>
                                                    <option value="Queatum Engineering">Queatum Engineering</option>
                                                    <option value="Machine Learning">Machine Learning</option>
                                                    <option value="Quantum Physics">Quantum Physics</option>
                                                    <option value="Math">Math</option>
                                                </select>
                                            </div>

                                            <div className="col-4 mb-3">
                                                <label htmlFor="language" className="form-label">Select Language</label>
                                                <select className="form-control form-select" name="language" id="language">
                                                    <option value=""></option>
                                                    <option value="English">English</option>
                                                    <option value="Spanish">Spanish</option>
                                                    <option value="বাংলা">বাংলা</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <label htmlFor="title" className="form-label">Title</label>
                                                <input type="text" className="form-control" name="title" id="title" />
                                            </div>

                                            <div className="col-12 mb-3">
                                                <label htmlFor="discription" className="form-label">Discription</label>
                                                <textarea className="form-control" name="discription" id="discription" rows="5"></textarea>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <label htmlFor="skils" className="form-label">What skills are required?</label>
                                                <div className="form-control p-3">
                                                    <ReactTags
                                                        classNames={{
                                                            tag: 'app-text-xs text-black bg-light-500 me-2 p-1 px-5 position-relative',
                                                            tagInputField: 'form-control w-25 mt-3',
                                                        }}
                                                        tags={tags}
                                                        delimiters={delimiters}
                                                        handleDelete={handleDelete}
                                                        handleAddition={handleAddition}
                                                        allowDragDrop={false}
                                                        inline={false}
                                                        inputFieldPosition="bottom"
                                                        autocomplete={false}
                                                        autofocus={false}
                                                        placeholder="Enter Skills here"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-12 mb-3">Development Budget</div>

                                            <div className="col-3 mb-3">
                                                <label htmlFor="budget_from" className="form-label text-muted">From</label>
                                                <input type="number" className="form-control" name="budget_from" id="budget_from" placeholder="$" />
                                            </div>

                                            <div className="col-3 mb-3">
                                                <label htmlFor="budget_to" className="form-label text-muted">To</label>
                                                <input type="number" className="form-control" name="budget_to" id="budget_to" placeholder="$" />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-12 form-label mb-3">Expected Commission</div>

                                            <div className="col-3 mb-3">
                                                <input type="text" className="form-control" name="commission" id="commission" placeholder="% 0.00" />
                                            </div>

                                            <div className="col-3 mb-3">
                                                <select className="form-control form-select" name="commission_type" id="commission_type">
                                                    <option value="Yearly">Yearly</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Daily">Daily</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <label htmlFor="deadline" className="form-label">Select Deadline</label>
                                                <input type="date" className="form-control" name="deadline" id="deadline" placeholder="March 15 2021" />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-12 app-text-xs">
                                                <div className="form-check m-0">
                                                    <input className="form-check-input me-2" type="checkbox" name="unspacified" id="unspacified" />
                                                    <label className="form-check-label app-text-xs" htmlFor="unspacified">Mark as Unspacified</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="form-check m-0">
                                        <input className="form-check-input mt-1 me-2" type="checkbox" name="terms" id="terms" />
                                        <label className="form-check-label" htmlFor="terms">I've agreed with the <Link to="#">terms</Link> and <Link to="#">condition</Link></label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end">
                                        <Link to="/investor/bids" className="btn btn-primary btn-lg px-5">Post</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default BidsForm;
