function FAQ() {
    return (
        <section className="faq">
            <div className="container">
                <div className="container-padding">
                    <div className="row">
                        <div className="col-12 text-center">
                            <span className="fs-1 fw-bold">FAQ'S</span>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-6 px-5 py-4">
                            <div className="fs-5 fw-bold">Why Quantasics?</div>
                            <div className="mt-3"><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</small></div>
                        </div>

                        <div className="col-6 px-5 py-4">
                            <div className="fs-5 fw-bold">What is the Quantasics?</div>
                            <div className="mt-3"><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</small></div>
                        </div>

                        <div className="col-6 px-5 py-4">
                            <div className="fs-5 fw-bold">How to join?</div>
                            <div className="mt-3"><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</small></div>
                        </div>

                        <div className="col-6 px-5 py-4">
                            <div className="fs-5 fw-bold">Why Qantasics?</div>
                            <div className="mt-3"><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</small></div>
                        </div>

                        <div className="col-6 px-5 py-4">
                            <div className="fs-5 fw-bold">What is the Qantasics?</div>
                            <div className="mt-3"><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</small></div>
                        </div>

                        <div className="col-6 px-5 py-4">
                            <div className="fs-5 fw-bold">How to join?</div>
                            <div className="mt-3"><small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</small></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQ;