import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Lightbox from '../../components/Lightbox';
import Gap from '../../components/UI/Gap';

function Details() {
    return (
        <>
            <Layout user="student">
                <section className="details-top">
                    <div className="container-fluid p-0">
                        <div className="">
                            <div className="row g-0 overflow-hidden" style={{ height: 530 }}>
                                <div className="col-8">
                                    <div className="position-relative">
                                        <img src="/assets/images/card/04.jpg" className="w-100" width="100" height="530" alt="Course" loading="lazy" />

                                        <Lightbox url="https://www.youtube.com/embed/MaKScf1UyT0" className="position-absolute top-50 start-50 translate-middle">
                                            <img src="/assets/images/icon/05.png" className="video-play" width="150" height="150" alt="Play" loading="lazy" />
                                        </Lightbox>
                                    </div>
                                </div>

                                <div className="col-4 bg-default text-white">
                                    <div className="text-center px-5 py-3">
                                        <div className="h4 mb-3">Lecture Title</div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="w-100 pe-2">
                                                <div className="progress progress-light">
                                                    <div className="progress-bar" role="progressbar" style={{ width: 30 }}></div>
                                                </div>
                                            </div>

                                            <div className="app-text-md text-end">30%</div>
                                        </div>
                                    </div>

                                    <div style={{ height: 440, overflowY: 'auto' }}>
                                        <div className="bg-primary cursor-pointer text-white border-bottom-dark">
                                            <div className="app-text-sm ps-5 py-4">
                                                <div className="app-text-md position-relative">
                                                    <div className="position-absolute" style={{ top: '-2px', left: '-20px' }}>
                                                        <img src="/assets/images/icon/15.svg" alt="Processing" />
                                                    </div>

                                                    CONCEPT TITLE
                                                </div>

                                                <div className="my-2">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting 
                                                    industry. Lorem Ipsum has been the industry's standard dummy text 
                                                    ever since the 1500s,
                                                </div>

                                                <Link to="#" className="btn btn-sm py-0 px-2" style={{ background: '#92E6F1' }}>
                                                    <img src="/assets/images/icon/17.svg" className="me-2" height="12" alt="Book" />
                                                    <span className="text-dark">Resource</span>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="cursor-pointer text-white border-bottom-dark">
                                            <div className="app-text-sm ps-5 py-4">
                                                <div className="app-text-md position-relative">
                                                    <div className="position-absolute" style={{ top: '-2px', left: '-25px' }}>
                                                        <img src="/assets/images/icon/16.svg" width="16" height="16" alt="Processing" />
                                                    </div>

                                                    CONCEPT TITLE
                                                </div>

                                                <div className="my-2">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting 
                                                    industry. Lorem Ipsum has been the industry's standard dummy text 
                                                    ever since the 1500s,
                                                </div>

                                                <Link to="#" className="btn btn-sm py-0 px-2" style={{ background: '#92E6F1' }}>
                                                    <img src="/assets/images/icon/17.svg" className="me-2" height="12" alt="Book" />
                                                    <span className="text-dark">Resource</span>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="cursor-pointer text-white">
                                            <div className="app-text-sm ps-5 py-4">
                                                <div className="app-text-md position-relative">
                                                    <div className="position-absolute" style={{ top: '-2px', left: '-25px' }}>
                                                        <img src="/assets/images/icon/16.svg" width="16" height="16" alt="Processing" />
                                                    </div>

                                                    CONCEPT TITLE
                                                </div>

                                                <div className="my-2">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting 
                                                    industry. Lorem Ipsum has been the industry's standard dummy text 
                                                    ever since the 1500s,
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center mb-3">
                                            <Link to="/course/quize-apply" className="btn btn-primary btn-sm px-4">Start Quizes</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="details-content pt-5">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-9">
                                    <div className="fs-5 fw-bold pb-4">Lecture Details</div>

                                    <p className="pb-4" style={{ lineHeight: 2, paddingRight: '80px' }}>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
                                        unknown printer took a galley of type and scrambled it to make a type specimen 
                                        book. It has survived not only five centuries, but also the leap into electronic 
                                        typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text 
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's 
                                        standard dummy text ever since the 1500s, when an 
                                    </p>

                                    <div className="fs-5 fw-bold pb-3">Concepts You Will Learn</div>

                                    <div className="pb-4">
                                        <div className="timeline-dark py-5">
                                            <div className="timeline-item ps-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">1</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>

                                            <div className="timeline-item ps-4 pt-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">2</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>

                                            <div className="timeline-item ps-4 pt-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">3</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>

                                            <div className="timeline-item ps-4 pt-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">4</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fs-5 fw-bold pb-3">Teacher</div>

                                    <div className="d-flex">
                                        <div className="pe-3">
                                            <img src="/assets/images/avatar/02.svg" width="50" height="50" alt="Teacher" loading="lazy" />
                                        </div>

                                        <div>
                                            <div className="app-text-md fw-bold">Daniel Walter Scott</div>
                                            <div className="app-text-sm text-muted">Mathematic</div>
                                        </div>
                                    </div>

                                    <p className="app-text-sm pt-3" style={{ lineHeight: 2, paddingRight: '180px' }}>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
                                        unknown printer took a galley of type and scrambled it to make a type specimen 
                                        book. It has survived not only five centuries, but also the leap into electronic 
                                        typesetting, remaining essentially unchanged. 
                                    </p>

                                    <div className="pb-5">
                                        <Link to="#" className="btn btn-gradient-primary btn-lg fw-bold mt-3" style={{ padding: '10px 45px' }}>Colab Link</Link>
                                    </div>

                                    <div className="fs-5 fw-bold pb-5">Q&A</div>

                                    <div>
                                        <div className="d-flex p-3 pb-2 mb-3 bg-light-300">
                                            <div className="pe-3 pt-1">
                                                <img src="/assets/images/icon/18.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <textarea className="textarea bg-light" rows="2" placeholder="Write a comment..."></textarea>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-3 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/icon/18.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student</div>
                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                    dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
                                                    book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                </div>

                                                <div className="d-flex ps-4 pt-3">
                                                    <div className="pe-3">
                                                        <img src="/assets/images/avatar/02.svg" width="30" height="30" alt="User" loading="lazy" />
                                                    </div>

                                                    <div className="w-100">
                                                        <div className="app-text-md fw-bold">Teacher</div>
                                                        <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-3 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/icon/18.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student</div>
                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                </div>

                                                <div className="d-flex ps-4 pt-3">
                                                    <div className="pe-3">
                                                        <img src="/assets/images/avatar/02.svg" width="30" height="30" alt="User" loading="lazy" />
                                                    </div>

                                                    <div className="w-100">
                                                        <div className="app-text-md fw-bold">Teacher</div>
                                                        <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-3 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/icon/18.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student</div>
                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                </div>

                                                <div className="d-flex ps-4 pt-3">
                                                    <div className="pe-3">
                                                        <img src="/assets/images/avatar/02.svg" width="30" height="30" alt="User" loading="lazy" />
                                                    </div>

                                                    <div className="w-100">
                                                        <div className="app-text-md fw-bold">Teacher</div>
                                                        <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="pagination d-flex justify-content-end">
                                                <Link to="#" className="pagination-item active">1</Link>
                                                <Link to="#" className="pagination-item">2</Link>
                                                <Link to="#" className="pagination-item">3</Link>
                                                <Link to="#" className="pagination-item">4</Link>
                                                <Link to="#" className="pagination-item">&gt;</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="fs-5 fw-bold pb-4">Career Paths</div>

                                    <div className="mb-4">
                                        <div className="border rounded shadow bg-white position-relative">
                                            <div className="rounded bg-default text-center app-text-md fw-bold text-white py-4">
                                                Become a Data<br />Sciencetist at Google
                                            </div>

                                            <div className="px-2 py-1">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <div className="app-text-sm mb-1">Created By</div>
                                                        <div className="app-text-md fw-bold mb-1">Mentor Name</div>
                                                        <div className="app-text-sm">Job Discription of Mentor</div>
                                                    </div>
                                                </div>

                                                <div className="app-text-sm pt-3">13 Lectures</div>
                                            </div>

                                            <Link to="/career/details" className="stretched-link"></Link>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <div className="border rounded shadow bg-white position-relative">
                                            <div className="rounded bg-default text-center app-text-md fw-bold text-white py-4">
                                                Become a Data<br />Sciencetist at Google
                                            </div>

                                            <div className="px-2 py-1">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <div className="app-text-sm mb-1">Created By</div>
                                                        <div className="app-text-md fw-bold mb-1">Mentor Name</div>
                                                        <div className="app-text-sm">Job Discription of Mentor</div>
                                                    </div>
                                                </div>

                                                <div className="app-text-sm pt-3">13 Lectures</div>
                                            </div>

                                            <Link to="/career/details" className="stretched-link"></Link>
                                        </div>
                                    </div>

                                    <div className="text-center pt-3">
                                        <Link to="/career/list" className="btn btn-outline-primary btn-sm bg-none fw-bold py-1 px-3">See More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Details;
