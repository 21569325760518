import Slider from "../../../components/Slider/Slider";
import SliderItem from "../../../components/Slider/Item";
import Gap from "../../../components/UI/Gap";

function Subject() {
    return (
        <>
            <section className="subjects">
                <div className="container">
                    <div className="container-padding">
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="section-heading">Explore by subjects</div>
                            </div>
                        </div> */}

                        <div className="row mt-3">
                            <Slider
                                api="subject"
                                skeleton={<Skeleton />}
                                options={{perPage: 5, perMove: 5, gap: 0}}
                                render={(items, instance) => {
                                    return items.map((item, itemIndex) =>
                                        <SliderItem key={`slider-item-key-${itemIndex}`}>
                                            <div className="tab-item">
                                                <div className="tab-name">{item.name}</div>
                                            </div>
                                        </SliderItem>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <Gap />
        </>
    );
}

function Skeleton() {
    return (
        <>
            {[...Array(5)].map((value, skeletonIndex) =>
                <div className="col" key={`skeleton-key-${skeletonIndex}`}>
                    <div className="app-skeleton app-skeleton-blink p-0">
                        <div className="app-skeleton-item" style={{height: '70px'}}></div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Subject;
