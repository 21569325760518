import { Link } from 'react-router-dom';
import Slider from "../../../components/Slider/Slider";
import SliderItem from "../../../components/Slider/Item";
import Gap from "../../../components/UI/Gap";

function Course() {
    let sections = ['Continue', 'Top Concepts', 'Most Recents'];

    let badge = {'Mathematic': 'primary', 'Physics': 'success', 'Engineering': 'danger', 'Machine': 'info'};

    return (
        <>
            {sections.map((section, index) =>
                <section key={`section-key-${index}`}>
                    <div className="container">
                        <div className="container-padding">
                            {/* <div className="row">
                                <div className="col-12">
                                    <div className="section-heading">{section}</div>
                                </div>
                            </div> */}

                            <div className="row mt-3">
                                <Slider
                                    api="course"
                                    data={{type: section.toLowerCase().replace(/\s+/g, '_')}}
                                    skeleton={<Skeleton />}
                                    render={(items, instance) => {
                                        return items.map((item, itemIndex) =>
                                            <SliderItem key={`slider-item-key-${itemIndex}`}>
                                                <Link to="/lecture/details" className="app-card-link">
                                                    <div className="course-card">
                                                        <div className="course-card-media">
                                                            <img src={item.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                            <span className={ 'course-card-tag badge bg-'+badge[item.category]}>{item.category}</span>
                                                        </div>

                                                        <div className="course-card-info">
                                                            <div className="course-card-title">{item.title}</div>
                                                            
                                                            <div className="course-card-rating">
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <span>{item.rating} ({item.reviews})</span>
                                                            </div>

                                                            <div className="course-card-desc">{item.participants} Participants</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </SliderItem>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <Gap />
                </section>
            )}
        </>
    );
}

function Skeleton() {
    return (
        <>
            {[...Array(4)].map((value, skeletonIndex) =>
                <div className="col-3" key={`skeleton-key-${skeletonIndex}`}>
                    <div className="app-skeleton app-skeleton-blink p-0">
                        <div className="app-skeleton-item rounded mb-3" style={{height: '150px'}}></div>
                        <div className="app-skeleton-item mb-3" style={{width: '80%', height: '40px'}}></div>
                        <div className="app-skeleton-item mb-2" style={{width: '70%', height: '15px'}}></div>
                        <div className="app-skeleton-item" style={{width: '70%', height: '15px'}}></div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Course;
