import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function Landing() {
    return (
        <>
            <Layout user="mentor">
                <section className="page-content">
                    <div className="container-fluid p-0">
                        <div className="page-banner-wrap text-white">
                            <div className="d-flex justify-content-center align-items-center h-100 bg-gradient-overlay">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h1 className="display-3">Build Your Team</h1>
                                        <div className="fs-3">Find the best person for your job</div>

                                        <div className="d-flex justify-content-center mt-3">
                                            <Link to="/mentor/dashboard" className="btn btn-white btn-lg px-4 fs-5 w-50 me-3">Become a Mentor</Link>
                                            <Link to="#" className="btn btn-outline-white btn-lg px-4 fs-5 w-50">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content pt-5 bg-white">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-5 pt-5">
                                    <div className="pt-5">
                                        <img src="/assets/images/card/08.png" className="mt-5" width="auto" height="500" alt="Mentor" loading="lazy" />
                                    </div>
                                </div>

                                <div className="col-7">
                                    <div className="fs-2 fw-bold pb-4">Who are Mentors</div>

                                    <p className="text-muted pb-2" style={{ lineHeight: 2 }}>
                                        Udacity Instructors are __ established industry professionals__ from Fortune
                                        500 and Global 2000 companies who have demonstrated leadership and
                                        expertise in cutting-edge tech fields. Here's what they bring to our courses:
                                    </p>

                                    <div className="fs-5 text-primary-500 pb-4">Industry Leaders</div>

                                    <div className="text-muted pb-2">
                                        <ul className="ps-5">
                                            <li className="mb-2 fw-bold">5+ years of demonstrated field expertise</li>
                                            <li className="mb-2">Ability to build intentional, engaging, effective and relevant course content</li>
                                            <li className="mb-2">Passion for their field</li>
                                        </ul>
                                    </div>

                                    <div className="fs-5 text-primary-500 pb-4">Committed Educators</div>

                                    <div className="text-muted pb-2">
                                        <ul className="ps-5">
                                            <li className="mb-2 fw-bold">Ability to break down concepts for new learners</li>
                                            <li className="mb-2">1+ years of education or teaching experience</li>
                                            <li className="mb-2">Available to work 15-20 hours per week</li>
                                        </ul>
                                    </div>

                                    <div className="fs-5 text-primary-500 pb-4">Impact-Driven Learners</div>

                                    <div className="text-muted pb-2">
                                        <ul className="ps-5">
                                            <li className="mb-2 fw-bold">Effective time management skills</li>
                                            <li className="mb-2">Growth mindset and eager to receive feedback</li>
                                            <li className="mb-2">Team player with a desire to give back</li>
                                        </ul>
                                    </div>

                                    <p className="text-muted pb-4" style={{ lineHeight: 2 }}>
                                        <b>Sound like you?</b> Scroll down for details about getting involved.<br />
                                        <b>Not sure if it's a fit?</b> Check out previous instructors’ success stories.
                                    </p>

                                    <Link to="#" className="btn btn-white py-3 px-5 fw-bold shadow">GO TO SUCCESS STORIES</Link>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 text-muted text-center pt-5">
                                    <div className="fs-3 py-4">What Instructors Do</div>

                                    <p className="pb-4" style={{ lineHeight: 2 }}>
                                        Thrive in an immersive course development experience that combines industry<br />
                                        visionaries with our team of instructional design and pedagogical experts.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-7 text-muted pt-5">
                                    <div className="fs-3 pb-4">How to get Involved</div>

                                    <div className="fs-5 text-primary-500 pb-4">1. Submit vour Interest</div>

                                    <p className="pb-2" style={{ lineHeight: 2 }}>
                                        Visit the Visit the <Link to="#">Instructor Interest Form</Link> and create a Udacity account to
                                        access the questionnaire. Complete the relevant fields, and we will contact
                                        you if an opportunity is available.
                                    </p>

                                    <div className="fs-5 text-primary-500 pb-4">2. Showcase your Skills <span className="text-primary-300 fw-bold fs-6">(HIGHLY RECOMMENDED)</span></div>

                                    <div className="pb-2" style={{ lineHeight: 2 }}>
                                        Teaching Samples are pre-recorded lessons that help our team map your
                                        expertise to a Udacity program. Candidates who submit one stand out and
                                        are more likely to be contacted about upcoming opportunities!

                                        <div>
                                            <div className="text-primary-300 fw-bold fs-6 py-3">HOW TO SUBMIT A TEACHING SAMPLE</div>
                                            
                                            <div className="ps-4">
                                                <div className="pb-3">
                                                    1. Teach one subject related to the skills and roles of interest in Udacity's 
                                                    <Link to="#"> Schools & Subjects of Interest</Link> list, or surprise us with a new subject in
                                                    tech!
                                                </div>

                                                <div>
                                                    2. Use the <Link to="#">Teaching Sample Guidelines</Link> to create your Teaching Sample,
                                                    and submit your final draft at the link below. Note: Submitting a
                                                    teaching sample that does not follow Udacity's guidelines is not
                                                    recommended.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fs-5 text-primary-500 pb-4">3. Launch Development</div>

                                    <p className="pb-2" style={{ lineHeight: 2 }}>
                                        Instructors kick-off course development with one week of onboarding, and
                                        then begin building their course alongside Udacity's Content Team until it is
                                        completed!
                                    </p>

                                    <Link to="#" className="btn btn-primary fs-6 py-3 px-5 mt-3">Become a Mentor</Link>
                                </div>

                                <div className="col-5">
                                    <div className="pt-5 text-end">
                                        <Gap height="200px" />

                                        <img src="/assets/images/card/09.png" className="mt-5" width="auto" height="450" alt="Mentor" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Gap height="80px" />
                </section>
            </Layout>
        </>
    );
}

export default Landing;
