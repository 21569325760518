import Layout from "../../components/Layout/Layout";
import Mentor from "./Partials/Mentor";
import Subject from "./Partials/Subject";
import Course from "./Partials/Course";
import Video from "./Partials/Video";
// import Stat from "./Partials/Stat";
import FAQ from "./Partials/FAQ";

function Home() {
	return (
        <>
            <Layout>
                <Mentor />

                <Subject />

                <Course />

                <Video />

                {/* <Stat /> */}

                <FAQ />
            </Layout>
        </>
	);
}

export default Home;