import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function StudentJoinedChart() {
    const data = canvas => {
        canvas.getContext('2d');
    
        return {
            labels: ['Jan 26', 'Jan 27', 'Jan 28', 'Jan 29', 'Jan 30', 'Jan 31', 'Feb 1', 'Feb 2', 'Feb 3', 'Feb 4', 'Feb 5', 'Feb 6', 'Feb 7'],
            datasets: [{
                label: 'Student Joined',
                data: [222, 399, 610, 222, 743, 678, 399, 650, 223, 676, 636, 721, 650],
                backgroundColor: '#90ADF1',
                borderColor: '#90ADF1',
                borderWidth: 0,
                datalabels: {
                    display: false,
                    offset: 10,
                    align: 'top',
                    anchor: 'end'
                }
            }]
        };
    }

    const options = {
        barPercentage: 0.6,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                bodyColor: '#000',
                bodyFont: {weight: 'bold'},
                backgroundColor: '#B8D5FF',
                yAlign: 'bottom',
                callbacks: {
                    title: function(context) {
                        return false;
                    },
                    label: function(context) {
                        return context.parsed.y;
                    }
                }
            },
            datalabels: {
                backgroundColor: '#B8D5FF',
                borderRadius: 4,
                color: '#000',
                font: {
                    weight: 'bold'
                },
                padding: 6,
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 10,
                    }
                },
                grid: {
                    lineWidth: 0
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    padding: 15,
                    font: {
                        size: 10,
                    }
                },
                grid: {
                    drawBorder: false
                }
            }
        }
    };

    return (
        <>
            <Bar
                height="290"
                data={data}
                plugins={[ChartDataLabels]}
                options={options}
            />
        </>
    );
}

export default StudentJoinedChart;
