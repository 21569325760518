import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import Layout from "../../components/Layout/Layout";

function Register() {
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push('student/dashboard');
    }

    return (
        <>
            <Layout header={false} footer={false}>
                <section className="login vh-100 bg-default">
                    <div className="text-center pt-5">
                        <Link to="/home">
                            <img src="/assets/images/logo-lg.png" alt="Logo" loading="lazy" />
                        </Link>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className="auth-card-wrap">
                            <div className="auth-card">
                                <div className="auth-card-title text-center pt-4">Sign Up</div>

                                <div className="px-4 mt-3">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-6 mb-3">
                                                <input type="text" className="form-control bg" name="first_name" id="first_name" placeholder="First Name" />
                                            </div>

                                            <div className="col-6 mb-3">
                                                <input type="text" className="form-control bg" name="last_name" id="last_name" placeholder="Last Name" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <input type="password" className="form-control bg" name="password" id="password" placeholder="Password" />
                                            </div>

                                            <div className="col-12 mb-3">
                                                <input type="password" className="form-control bg" name="confirm_password" id="confirm_password" placeholder="Confirm Password" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-center my-3">
                                                <button className="btn btn-primary btn-sm px-4">Sign Up</button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="pb-3" style={{ fontSize: '10px' }}>
                                        Already have an account &nbsp; &nbsp;
                                        
                                        <Link to="/login">Log in</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
}

export default Register;