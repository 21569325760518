import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function Details() {
    let outlines = [
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/06.jpg',
        },
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/02.jpg',
        },
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/04.jpg',
        },
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/05.jpg',
        },
    ];

    return (
        <>
            <Layout user="student">
                <section className="page-content">
                    <div className="container-fluid p-0">
                        <div className="bg-default p-5">
                            <div className="row">
                                <div className="col-12 text-center text-white fw-bold">
                                    <h1>Become a Data Sciencetist<br />at Google</h1>
                                    <Link to="/student/dashboard" className="btn btn-gradient-primary btn-lg fw-bold mt-3" style={{ padding: '10px 45px' }}>Join Career Path</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="page-content">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex my-4">
                                        <div className="pe-3">
                                            <img src="/assets/images/avatar/03.svg" width="57" height="57" alt="Teacher" loading="lazy" />
                                        </div>

                                        <div>
                                            <div className="app-text-xs">Created By</div>
                                            <div className="app-text-md fw-bold">Mentor Name</div>
                                            <div className="app-text-xs">Job Discription of Mentor</div>
                                        </div>
                                    </div>

                                    <div className="fs-5 fw-bold pb-3">About The Lecture</div>

                                    <p className="app-text-sm pt-2" style={{ lineHeight: 2 }}>
                                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                    </p>

                                    <div className="fs-5 fw-bold py-3">Path Outline</div>

                                    <div className="pb-4">
                                        <div className="timeline timeline-border-4 pt-3 pb-5">
                                            {outlines.map((outline, index) =>
                                                <div className="timeline-item position-relative ps-4 mt-3" key={'outline-key-'+index}>
                                                    <div className="bg-white border rounded shadow d-flex p-3 position-relative">
                                                        <div className="app-text-md">
                                                            <div className="position-absolute timeline-step timeline-step-lg timeline-step-center">{index + 1}</div>
                                                            <img src={outline.image} className="rounded" width="150" height="115" alt="Career" loading="lazy" />
                                                        </div>

                                                        <div className="ps-3">
                                                            <div className="app-text-md fw-bold pb-2">{outline.title}</div>
                                                            <div className="app-text-sm">{outline.description}</div>
                                                        </div>
                                                    </div>

                                                    <Link to="/lecture/details" className="stretched-link"></Link>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <Link to="/student/dashboard" className="btn btn-gradient-primary btn-lg fw-bold mt-3" style={{ padding: '10px 45px' }}>Join Career Path</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Details;
