import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function QuizeApply() {
    let quizes = [
        {
            'question': 'Data science is the process of diverse set of data through?',
            'options': [
                'organizing data',
                'processing data',
                'analysing data',
                'All of the above',
            ],
        },
        {
            'question': 'The modern conception of data science as an independent discipline is sometimes attributed to?',
            'options': [
                'William S.',
                'John McCarthy',
                'Arthur Samuel',
                'Satoshi Nakamoto',
            ],
        },
        {
            'question': 'Which of the following language is used in Data science?',
            'options': [
                'C',
                'C++',
                'R',
                'Ruby',
            ],
        },
        {
            'question': 'Which of the following is not a part of data science process?',
            'options': [
                'Discovery',
                'Model Planning',
                'Communication Building',
                'Operationalize',
            ],
        },
        {
            'question': 'Which of the following are the Data Sources in data science?',
            'options': [
                'Structured',
                'UnStructured',
                'Both A and B',
                'None Of the above',
            ],
        },
    ];

    return (
        <>
            <Layout user="student">
                <Gap />

                <section className="page-content">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row justify-content-center">
                                <div className="col-8">
                                    {quizes.map((quize, index) =>
                                        <div className="quize-item bg-white text-muted py-3 px-5 mb-5 border-muted" key={'quize-key-'+index}>
                                            <div className="app-text-md fw-bold">{(index+1) + '. ' + quize.question}</div>
                                            
                                            <div className="mt-3">
                                                {quize.options.map((option, optionIndex) =>
                                                    <div className="form-check mb-3" key={'quize-option-key-'+optionIndex}>
                                                        <input className="form-check-input lg" type="checkbox" id={'quize_' + index + optionIndex} />
                                                        <label className="form-check-label ps-3" htmlFor={'quize_' + index + optionIndex}>{option}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="text-center">
                                        <Link to="/student/dashboard" className="btn btn-primary btn-sm px-4">Done</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default QuizeApply;