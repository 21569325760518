import { Link } from 'react-router-dom';

function Header(props) {
    let subjects = [
        {name: 'Queatum Engineering', image: '/assets/images/icon/01.png'},
        {name: 'Machine Learning', image: '/assets/images/icon/02.png'},
        {name: 'Quantum Physics', image: '/assets/images/icon/03.png'},
        {name: 'Math', image: '/assets/images/icon/04.png'}
    ];
    
    let careers = [
        {name: 'Data Scientist', image: '/assets/images/card/01.jpg'},
        {name: 'Backend\nDeveloper', image: '/assets/images/card/02.jpg'},
        {name: 'UX Designer', image: '/assets/images/card/03.jpg'},
        {name: 'UI Designer', image: '/assets/images/card/04.jpg'},
        {name: 'Frontend\nDeveloper', image: '/assets/images/card/05.jpg'},
        {name: 'Project\nManager', image: '/assets/images/card/06.jpg'}
    ];

    return (
        <header className="app-header">
            <div className="app-header-middle">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col d-flex align-items-center">
                            <span className="side-menu-toggle"><i className="bi bi-list"></i></span>

                            <Link to="/home">
                                <img src="/assets/images/logo.png" className="app-logo" alt="Logo" loading="lazy" width="167" />
                            </Link>
                        </div>

                        <div className="col d-flex align-items-center">
                            <div className="dropdown app-dropdown pe-3 app-border-right">
                                <Link className="app-dropdown-toggle app-menu-link" to="#" role="button" data-bs-toggle="dropdown">Subjects</Link>

                                <ul className="dropdown-menu app-dropdown-menu">
                                    {subjects.map((subject, index) =>
                                        <li key={'subject-key-'+index}>
                                            <Link to="/subject/details" className="dropdown-item text-uppercase">
                                                <img src={subject.image} className="dropdown-item-icon" alt="Icon" />
                                                {subject.name}
                                            </Link>
                                        </li>
                                    )}

                                    <li className="mt-2 ps-2">
                                        <Link className="dropdown-item" to="#">
                                            Browse All Subject
                                        </Link>
                                    </li>
                                </ul>   
                            </div>

                            <div className="dropdown app-dropdown ps-4">
                                <Link className="app-dropdown-toggle app-menu-link" to="#" role="button" data-bs-toggle="dropdown">Careers</Link>

                                <div className="dropdown-menu app-dropdown-menu mega py-3 px-5">
                                    <div className="row g-2">
                                        {careers.map((career, index) =>
                                            <div className="col-4" key={'career-key-'+index}>
                                                <Link to="/career/list" className="app-card-link">
                                                    <div className="app-card app-card-rounded text-center d-flex justify-content-center align-items-center" style={{ backgroundImage: 'linear-gradient(270deg, rgba(93, 113, 234, 0.85) 0%, rgba(146, 230, 241, 0.85) 100%), url('+career.image+')', height: '76px' }}>
                                                        <div className="app-card-title">{career.name}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <Link to="/career/list" className="btn btn-outline-white rounded-pill app-text-xs">View More Concepts</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col d-flex align-items-center justify-content-end">
                            <form className="d-flex app-menu-search" action="#">
                                <input type="text" placeholder="Search Concepts" required />
                                <button type="submit"><i className="bi bi-search"></i></button>
                            </form>
                        </div>

                        {props.user &&
                            <div className="col-2 d-flex align-items-center justify-content-end">
                                <div className="dropdown header-dropdown header-notification-dropdown">
                                    <div className="d-flex align-items-center me-4 position-relative" data-bs-toggle="dropdown">
                                        <img src="/assets/images/icon/12.png" alt="Notification" />
                                        <span className="header-notification-badge">3</span>
                                    </div>

                                    <ul className="dropdown-menu notification-dropdown-menu arrow-top border-0 shadow app-text-sm" style={{ width: '280px' }}>
                                        <div className="d-flex justify-content-between align-items-center px-2 pb-2">
                                            <div>Notification</div>
                                            <img src="/assets/images/icon/13.svg" alt="Settings" />
                                        </div>

                                        <div className="overflow-auto" style={{ maxHeight: '260px' }}>
                                            <Link className="dropdown-item d-flex" to="#">
                                                <div>
                                                    <div className="notification-item-icon">
                                                        <img className="pt-2" src="/assets/images/icon/14.svg" alt="Icon" />
                                                    </div>
                                                </div>

                                                <div className="notification-item-content ps-2">
                                                    <div className="app-text-xs">Notification title gose here</div>
                                                    <div className="app-text-xs mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry....
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link className="dropdown-item d-flex" to="#">
                                                <div>
                                                    <div className="notification-item-icon">
                                                        <img className="pt-2" src="/assets/images/icon/14.svg" alt="Icon" />
                                                    </div>
                                                </div>

                                                <div className="notification-item-content ps-2">
                                                    <div className="app-text-xs">Notification title gose here</div>
                                                    <div className="app-text-xs mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry....
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link className="dropdown-item d-flex" to="#">
                                                <div>
                                                    <div className="notification-item-icon">
                                                        <img className="pt-2" src="/assets/images/icon/14.svg" alt="Icon" />
                                                    </div>
                                                </div>

                                                <div className="notification-item-content ps-2">
                                                    <div className="app-text-xs">Notification title gose here</div>
                                                    <div className="app-text-xs mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry....
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link className="dropdown-item d-flex" to="#">
                                                <div>
                                                    <div className="notification-item-icon">
                                                        <img className="pt-2" src="/assets/images/icon/14.svg" alt="Icon" />
                                                    </div>
                                                </div>

                                                <div className="notification-item-content ps-2">
                                                    <div className="app-text-xs">Notification title gose here</div>
                                                    <div className="app-text-xs mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry....
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link className="dropdown-item d-flex" to="#">
                                                <div>
                                                    <div className="notification-item-icon">
                                                        <img className="pt-2" src="/assets/images/icon/14.svg" alt="Icon" />
                                                    </div>
                                                </div>

                                                <div className="notification-item-content ps-2">
                                                    <div className="app-text-xs">Notification title gose here</div>
                                                    <div className="app-text-xs mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry....
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link className="dropdown-item d-flex" to="#">
                                                <div>
                                                    <div className="notification-item-icon">
                                                        <img className="pt-2" src="/assets/images/icon/14.svg" alt="Icon" />
                                                    </div>
                                                </div>

                                                <div className="notification-item-content ps-2">
                                                    <div className="app-text-xs">Notification title gose here</div>
                                                    <div className="app-text-xs mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry....
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </ul>   
                                </div>

                                <div className="dropdown header-dropdown header-user-dropdown">
                                    <div className="d-flex align-items-center" data-bs-toggle="dropdown">
                                        <div className="header-user-icon">
                                            <img src="/assets/images/avatar/01.png" alt="User" />
                                        </div>

                                        <span className="app-text-sm fs-6 fw-bold ms-2">Jonathan W.</span>
                                    </div>

                                    <ul className="dropdown-menu arrow-top border-0 shadow">
                                        <li>
                                            <Link to="/student/profile" className="dropdown-item">View Profile</Link>
                                        </li>
                                        
                                        <li>
                                            <Link to="/student/dashboard" className="dropdown-item">Student Dashboard</Link>
                                        </li>
                                        
                                        <li>
                                            <Link className="dropdown-item" to="#">Settings</Link>
                                        </li>
                                        
                                        <li>
                                            <Link className="dropdown-item" to="#">Support</Link>
                                        </li>

                                        <li>
                                            <span className="dropdown-item-text">Sign up/Log in as </span>

                                            <ul className="nav flex-column ps-5">
                                                <li>
                                                    <Link to="/teacher" className="dropdown-item opacity-75" style={{ padding: '3px 0px' }}>Teacher</Link>
                                                </li>

                                                <li>
                                                    <Link to="/mentor" className="dropdown-item opacity-75" style={{ padding: '3px 0px' }}>Mentor</Link>
                                                </li>

                                                <li>
                                                    <Link to="/investor/bids" className="dropdown-item opacity-75" style={{ padding: '3px 0px' }}>Investor</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        
                                        <li>
                                            <Link to="/" className="dropdown-item">Log out</Link>
                                        </li>
                                    </ul>   
                                </div>
                            </div>
                        }

                        {! props.user &&
                            <div className="col-2 d-flex align-items-center justify-content-end">
                                <Link to="/login" className="btn btn-link app-menu-link">Log in</Link>

                                <Link to="/register" className="app-menu-link app-menu-btn-link ms-2">Join Now</Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;