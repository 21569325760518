import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';
import CareerPathSlider from './_CareerPathSlider';

function Profile() {
    let courses = [
        {
            'title': 'Lecture Title',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/06.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Lecture Title',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/02.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Lecture Title',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/04.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Lecture Title',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/05.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Lecture Title',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/02.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Lecture Title',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/04.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        }
    ];

    return (
        <>
            <Layout user="student">
                <Gap />

                <section className="profile-stat">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-5">
                                    <div className="card rounded-0 p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="w-100 text-center">
                                                <img className="app-block-md rounded-circle" src="/assets/images/avatar/01.png" alt="User" />
                                                <div className="fs-6 pt-2 fw-bold">Student Name</div>
                                            </div>

                                            <div className="w-100 text-center">
                                                <div className="d-flex">
                                                    <div className="w-100 d-flex flex-column align-items-center">
                                                        <div className="app-block-md rounded-circle bg-primary-300 text-white fs-4 fw-bold pt-2">13</div>
                                                        <div className="fs-6 pt-2 fw-bold">Lectures</div>
                                                    </div>

                                                    <div className="w-100 d-flex flex-column align-items-center">
                                                        <div className="app-block-md rounded-circle bg-primary-300 text-white fs-4 fw-bold pt-2">68</div>
                                                        <div className="fs-6 pt-2 fw-bold">Concepts</div>                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile-concept pt-4">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="fs-5 fw-bold">Lectures Learned</div>
                                </div>
                            </div>
                        
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav app-tabs mt-3">
                                        <li className="nav-item active">
                                            <a href="#learned" className="nav-link active" data-bs-toggle="tab">Learned</a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#learned" className="nav-link" data-bs-toggle="tab">In Progress</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="learned">
                                            <div className="row mt-5">
                                                <div className="splide course-splide">
                                                    <div className="splide__arrows">
                                                        <button className="splide__arrow splide__arrow--prev"><i className="bi bi-chevron-left"></i></button>
                                                        <button className="splide__arrow splide__arrow--next"><i className="bi bi-chevron-right"></i></button>
                                                    </div>

                                                    <div className="splide__track pb-1">
                                                        <ul className="splide__list">
                                                            {courses.map((course, index) =>
                                                                <li className="splide__slide" key={'course-key-'+index}>
                                                                    <div className="course-card">
                                                                        <div className="course-card-media">
                                                                            <img src={course.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                                        </div>

                                                                        <div className="course-card-info">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div>
                                                                                    <div className="app-text-sm">{course.sub_title}</div>
                                                                                    <div className="app-text-lg">{course.title}</div>
                                                                                </div>

                                                                                <div className="text-end">
                                                                                    <Link to="#" className="btn btn-sm btn-outline-dark py-0 px-2 opacity-50 app-text-sm">View</Link>
                                                                                </div>
                                                                            </div>

                                                                            <div className="course-card-rating">
                                                                                <i className="bi bi-star-fill"></i>
                                                                                <i className="bi bi-star-fill"></i>
                                                                                <i className="bi bi-star-fill"></i>
                                                                                <i className="bi bi-star-fill"></i>
                                                                                <i className="bi bi-star-fill"></i>
                                                                                <span>{course.rating} ({course.reviews})</span>
                                                                            </div>

                                                                            <div className="course-card-desc">{course.participants} Participants</div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile-career pt-4">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="fs-5 fw-bold">Career Paths</div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <CareerPathSlider />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile-quiz-score pt-4">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="fs-5 fw-bold">Quiz Score Board</div>
                                </div>
                            </div>
                        
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="border rounded bg-white p-2">
                                        <table className="table table-borderless mb-0 app-text-sm">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width="3%">No.</th>
                                                    <th className="text-start">Lecture Title</th>
                                                    <th className="text-center" width="8%">Score</th>
                                                </tr>
                                            </thead>

                                            <tbody className="text-muted">
                                                <tr>
                                                    <td className="text-center">1.</td>
                                                    <td className="text-start">Lecture Name goes here 1</td>
                                                    <td className="text-center">57/100</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center">2.</td>
                                                    <td className="text-start">Lecture Name goes here 2</td>
                                                    <td className="text-center">45/80</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center">3.</td>
                                                    <td className="text-start">Lecture Name goes here 3</td>
                                                    <td className="text-center">16/30</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center">4.</td>
                                                    <td className="text-start">Lecture Name goes here 4</td>
                                                    <td className="text-center">16/30</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center">5.</td>
                                                    <td className="text-start">Lecture Name goes here 5</td>
                                                    <td className="text-center">16/30</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile-certificates pt-4">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="fs-5 fw-bold">My Certificates</div>
                                </div>
                            </div>
                        
                            <div className="row mt-3">
                                <div className="col-3">
                                    <div className="border rounded shadow bg-white position-relative">
                                        <img src="/assets/images/certificate/01.png" alt="certificate" className="rounded w-100" height="170" />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="border rounded shadow bg-white position-relative">
                                        <img src="/assets/images/certificate/02.png" alt="certificate" className="rounded w-100" height="170" />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="border rounded shadow bg-white position-relative">
                                        <img src="/assets/images/certificate/03.png" alt="certificate" className="rounded w-100" height="170" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Profile;
