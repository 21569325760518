import axios from 'axios';
import querystring from 'query-string';

const config = {
    base: 'https://quantasics.brandecy.com/api',
    routes: {
        'mentor': 'mentor.php',
        'course': 'course.php',
        'subject': 'subject.php',
    },
};

const route = (api) => {
    return api ? `${config.base}/` + config.routes[api] : null;
}

const get = (url, data) => {
    let apiUrl = url + '?' + querystring.stringify(data);

    return axios.get(apiUrl).then(response => response.data);
};

const exports = {config, route, get};

export default exports;
