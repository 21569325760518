import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function Form() {
    let outlines = [
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/06.jpg',
        },
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/02.jpg',
        },
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/04.jpg',
        },
        {
            'title': 'Lecture Title',
            'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,',
            'image': '/assets/images/card/05.jpg',
        },
    ];

    return (
        <>
            <Layout>
                <Gap height="40px" />

                <section className="page-content">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end mb-4">
                                        <Link to="#" className="btn btn-outline-primary me-3 px-4">Save Draft</Link>
                                        <Link to="/career/details" className="btn btn-primary px-4">Publish</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="bg-white border-muted py-3 px-5">
                                        <form>
                                            <div className="row">
                                                <div className="col-5 mb-3">
                                                    <label htmlFor="subject" className="form-label">Select Title</label>
                                                    <select className="form-control form-select" name="subject" id="subject">
                                                        <option value=""></option>
                                                        <option value="Queatum Engineering">Queatum Engineering</option>
                                                        <option value="Machine Learning">Machine Learning</option>
                                                        <option value="Quantum Physics">Quantum Physics</option>
                                                        <option value="Math">Math</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="path_title" className="form-label">Path Title</label>
                                                    <input type="text" className="form-control" name="path_title" id="path_title" />
                                                </div>

                                                <div className="col-12 mb-3">
                                                    <label htmlFor="about" className="form-label">About</label>
                                                    <textarea className="form-control" name="about" id="about" rows="5"></textarea>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="py-4 mt-5">
                                                        <div className="timeline timeline-border-4 pt-3 pb-5">
                                                            {outlines.map((outline, index) =>
                                                                <div className="timeline-item position-relative ps-4 mt-3" key={'outline-key-'+index}>
                                                                    <div className="bg-white border rounded shadow d-flex p-3 position-relative">
                                                                        <div className="app-text-md">
                                                                            <div className="position-absolute timeline-step timeline-step-lg timeline-step-center">{index + 1}</div>
                                                                            <img src={outline.image} className="rounded" width="150" height="115" alt="Career" loading="lazy" />
                                                                        </div>

                                                                        <div className="ps-3">
                                                                            <div className="app-text-md fw-bold pb-2">{outline.title}</div>
                                                                            <div className="app-text-sm">{outline.description}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 text-center my-4">
                                                    <Link to="#" className="btn btn-primary px-5">Add Lectures</Link>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="mb-3">Add Additional Lectures that needs to be learned</div>

                                                <div className="col-12">
                                                    <input type="text" className="form-control rounded-0 border-bottom-0" name="concept_title" id="concept_title" placeholder="Concept Title" />
                                                </div>

                                                <div className="col-12">
                                                    <textarea className="form-control rounded-0" name="concept_discription" id="concept_discription" placeholder="Concept Discription" rows="5"></textarea>
                                                </div>

                                                <div className="d-flex justify-content-center text-center my-4">
                                                    <div className="app-block-40 cursor-pointer rounded-circle bg-primary-300 text-white fs-4 pt-1">+</div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="mt-5 mb-3">Create Quiz</div>

                                                <div className="col-12">
                                                    <div className="border border-dark p-3">
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <input type="text" className="form-control ps-0 rounded-0 border-top-0 border-x-0 border-bottom" name="question" id="question" placeholder="Question" />
                                                            </div>

                                                            <div className="col-4">
                                                                <select className="form-control form-select" name="short_answer" id="short_answer">
                                                                    <option value="">Short Answer</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-8 mt-2">
                                                            <input type="text" className="form-control ps-0 rounded-0 border-top-0 border-x-0 border-bottom border-dashed" name="type_answer" id="type_answer" placeholder="Type Answer Here" />
                                                        </div>

                                                        <div className="d-flex justify-content-center text-center my-4">
                                                            <div className="app-block-40 cursor-pointer rounded-circle bg-primary-300 text-white fs-4 pt-1">+</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="mt-5 mb-3">Upload Certificate</div>

                                                <div className="col-3">
                                                    <label htmlFor="certificate" className="w-100 mb-5">
                                                        <div className="border d-flex justify-content-center align-items-center cursor-pointer py-5" id="upload">
                                                            <img src="/assets/images/icon/30.svg" width="30" height="30" alt="Upload" loading="lazy" />
                                                            <input type="file" className="d-none" name="certificate" id="certificate" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Form;