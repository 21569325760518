import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer p-5">
            <div className="container-fluid" style={{ transform: 'inherit' }}>
                <div className="row">
                    <div className="col-2">
                        <div className="footer-heading">ATHENAEUM</div>

                        <Link to="#" className="footer-link">About</Link>
                        <Link to="#" className="footer-link">Blog</Link>
                        <Link to="#" className="footer-link">FAQ’s</Link>
                    </div>

                    <div className="col-2">
                        <div className="footer-heading">Plans</div>

                        <Link to="#" className="footer-link">For Individual</Link>
                        <Link to="#" className="footer-link">For Business</Link>
                        <Link to="#" className="footer-link">For Education</Link>
                    </div>

                    <div className="col-2">
                        <div className="footer-heading">Connect</div>
                        
                        <Link to="#" className="footer-link">Contact us</Link>
                        <Link to="#" className="footer-link">Help and Support</Link>
                    </div>

                    <div className="col-2">
                        <div className="footer-heading">Teaching & Guiding</div>
                        
                        <Link to="#" className="footer-link">Become a Teacher</Link>
                        <Link to="#" className="footer-link">Become a Mentor</Link>
                        <Link to="#" className="footer-link">Create a Program</Link>
                    </div>

                    <div className="col-2">
                        <div className="footer-heading">Legal</div>
                        
                        <Link to="#" className="footer-link">Terms And Services</Link>
                        <Link to="#" className="footer-link">Privacy Policy</Link>
                        <Link to="#" className="footer-link">Site Map</Link>
                    </div>

                    <div className="col-2">
                        <div className="footer-heading">Support</div>
                        
                        <Link to="#" className="footer-link">Become an Investor</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-4 mb-5">
                        <Link to="#" className="footer-link">Follow us</Link>

                        <div className="d-flex mt-2">
                            <Link to="#" className="social-link"><i className="bi bi-facebook"></i></Link>
                            <Link to="#" className="social-link"><i className="bi bi-twitter"></i></Link>
                            <Link to="#" className="social-link"><i className="bi bi-youtube"></i></Link>
                            <Link to="#" className="social-link"><i className="bi bi-instagram"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-logo">
                <Link to="/home" className="d-block">
                    <img src="/assets/images/logo-circle.png" alt="Logo" loading="lazy" width="77" />
                </Link>
            </div>
        </footer>
    );
}

export default Footer;