import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function LectureDetails() {
    let courses = [
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Mathematic',
            'cover': '/assets/images/card/05.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Physics',
            'cover': '/assets/images/card/04.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Engineering',
            'cover': '/assets/images/card/02.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Machine',
            'cover': '/assets/images/card/03.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Mathematic',
            'cover': '/assets/images/card/04.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Mathematic',
            'cover': '/assets/images/card/05.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        }
    ];

    let badge = {'Mathematic': 'primary', 'Physics': 'success', 'Engineering': 'danger', 'Machine': 'info'};

    return (
        <>
            <Layout user="student">
                <section className="details-top">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row pt-5">
                                <div className="col-6">
                                    <h1 className="app-text-lg fw-bold mt-5">Lecture Titles Goes Here.</h1>

                                    <div className="app-text-md pb-2">Meta Discription will goes here,</div>

                                    <div className="mt-3 d-flex align-items-center">
                                        <i className="bi bi-star-fill text-yellow me-1"></i>
                                        <i className="bi bi-star-fill text-yellow me-1"></i>
                                        <i className="bi bi-star-fill text-yellow me-1"></i>
                                        <i className="bi bi-star-fill text-yellow me-1"></i>
                                        <i className="bi bi-star-fill text-muted me-1"></i>
                                        <span className="app-text-sm fw-bold ms-2">(38 Ratings)</span>
                                    </div>

                                    <div className="mt-4 d-flex">
                                        <Link to="/course/details" className="btn btn-gradient-primary btn-lg fw-bold me-3">Join Lecture</Link>
                                        <a href="#careerPathModal" className="btn btn-primary fw-bold" data-bs-toggle="modal">
                                            <img src="/assets/images/icon/29.svg" width="15" height="15" alt="Icon" loading="lazy" />
                                        </a>

                                        <div className="modal fade" id="careerPathModal" tabIndex="-1">
                                            <div className="modal-dialog modal-dialog-centered" style={{ width: 400 }}>
                                                <div className="modal-content rounded border-0">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Add to Career Cath</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                    </div>

                                                    <div className="modal-body p-0 app-text-sm">
                                                        <div className="border-top border-bottom p-2">
                                                            <label className="d-flex align-items-center p-1 cursor-pointer">
                                                                <input type="checkbox" className="me-2" />
                                                                <span>Become a Data Sciencetist at Google</span>
                                                            </label>
                                                        </div>

                                                        <div className="border-top border-bottom p-2">
                                                            <label className="d-flex align-items-center p-1 cursor-pointer">
                                                                <input type="checkbox" className="me-2" />
                                                                <span>Become a Data Sciencetist at Google</span>
                                                            </label>
                                                        </div>

                                                        <div className="border-top border-bottom p-2">
                                                            <label className="d-flex align-items-center p-1 cursor-pointer">
                                                                <input type="checkbox" className="me-2" />
                                                                <span>Become a UI/UX at Google</span>
                                                            </label>
                                                        </div>

                                                        <div className="border-top border-bottom p-2">
                                                            <label className="d-flex align-items-center p-1 cursor-pointer">
                                                                <input type="checkbox" className="me-2" />
                                                                <span>Become a Frontend-Developer at Google</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="modal-footer justify-content-center">
                                                        <Link to="/career/form" className="btn btn-primary my-2">+ Create New Career Path</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-6">
                                    <img src="/assets/images/card/06.jpg" className="rounded" width="100%" height="300" alt="Lecture" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="details-content pt-5">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row g-0 pt-3">
                                <div className="col-9">
                                    <div className="fs-5 fw-bold pb-4">About The Lecture</div>

                                    <p className="app-text-sm pb-4" style={{ lineHeight: 2, paddingRight: '80px' }}>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
                                        unknown printer took a galley of type and scrambled it to make a type specimen 
                                        book. It has survived not only five centuries, but also the leap into electronic 
                                        typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text 
                                        of the printing and typesetting industry. Lorem Ipsum has been the industry's 
                                        standard dummy text ever since the 1500s, when an 
                                    </p>

                                    <div className="fs-5 fw-bold pb-3">Concepts You Will Learn</div>

                                    <div className="pb-4">
                                        <div className="timeline py-5">
                                            <div className="timeline-item ps-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">1</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>

                                            <div className="timeline-item ps-4 pt-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">2</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>

                                            <div className="timeline-item ps-4 pt-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">3</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>

                                            <div className="timeline-item ps-4 pt-4">
                                                <div className="app-text-md pb-3 position-relative">
                                                    <div className="position-absolute timeline-step">4</div>
                                                    <span className="text-black">Concept Title</span>
                                                </div>

                                                <div className="app-text-sm">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem<br />
                                                    Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fs-5 fw-bold pb-4">Career Paths</div>

                                    <div className="row pb-5">
                                        <div className="col-4">
                                            <div className="border rounded shadow bg-white position-relative">
                                                <div className="rounded bg-default text-center app-text-md fw-bold text-white py-4">
                                                    Become a Data<br />Sciencetist at Google
                                                </div>

                                                <div className="px-2 py-1">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="app-text-sm mb-1">Created By</div>
                                                            <div className="app-text-md fw-bold mb-1">Mentor Name</div>
                                                            <div className="app-text-sm">Job Discription of Mentor</div>
                                                        </div>
                                                    </div>

                                                    <div className="app-text-sm pt-3">13 Lectures</div>
                                                </div>

                                                <Link to="/career/details" className="stretched-link"></Link>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="border rounded shadow bg-white position-relative">
                                                <div className="rounded bg-default text-center app-text-md fw-bold text-white py-4">
                                                    Become a Data<br />Sciencetist at Google
                                                </div>

                                                <div className="px-2 py-1">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="app-text-sm mb-1">Created By</div>
                                                            <div className="app-text-md fw-bold mb-1">Mentor Name</div>
                                                            <div className="app-text-sm">Job Discription of Mentor</div>
                                                        </div>
                                                    </div>

                                                    <div className="app-text-sm pt-3">13 Lectures</div>
                                                </div>

                                                <Link to="/career/details" className="stretched-link"></Link>
                                            </div>
                                        </div>

                                        <div className="col-4 d-flex justify-content-center align-items-center">
                                            <Link to="/career/list" className="btn-link fw-bold">See More</Link>
                                        </div>
                                    </div>

                                    <div className="fs-5 fw-bold pb-4">Reviews and Ratings</div>

                                    <div>
                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/01.png" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                    dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
                                                    book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/04.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/05.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="mt-4 pagination pagination-white d-flex justify-content-end">
                                                <Link to="#" className="pagination-item active">1</Link>
                                                <Link to="#" className="pagination-item">2</Link>
                                                <Link to="#" className="pagination-item">3</Link>
                                                <Link to="#" className="pagination-item">4</Link>
                                                <Link to="#" className="pagination-item">&gt;</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="p-3 rounded bg-light-300">
                                        <div className="text-center">
                                            <img src="/assets/images/avatar/04.svg" width="50" height="50" alt="Teacher" loading="lazy" />
                                            <div className="app-text-sm mt-2">Created By</div>
                                            <div className="app-text-sm fw-bold">Josh William</div>
                                        </div>

                                        <div className="ps-4 pt-5">
                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/19.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">$12.99</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/20.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">English</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/21.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">36 Recomandation</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/22.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">Subject Name</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/23.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">Length</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/24.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">Created at</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/25.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">updated at</div>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <div className="app-text-sm fw-bold mb-3">Tags</div>

                                            <div className="pb-4 d-flex flex-wrap align-items-center">
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 1</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 2</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 3</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 4</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 5</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 6</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 7</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 8</Link>
                                                <Link to="#" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3">Tag 9</Link>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <div className="app-text-sm fw-bold mb-3">Share</div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <Link to="#" className="d-flex justify-content-center align-items-center app-block-40 rounded-circle bg-gray me">
                                                    <img src="/assets/images/icon/26.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                </Link>

                                                <Link to="#" className="d-flex justify-content-center align-items-center app-block-40 rounded-circle bg-gray me">
                                                    <img src="/assets/images/icon/27.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                </Link>

                                                <Link to="#" className="d-flex justify-content-center align-items-center app-block-40 rounded-circle bg-gray me">
                                                    <img src="/assets/images/icon/28.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="related-concepts mt-3">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-heading">Related Concepts</div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="splide course-splide">
                                    <div className="splide__arrows">
                                        <button className="splide__arrow splide__arrow--prev"><i className="bi bi-chevron-left"></i></button>
                                        <button className="splide__arrow splide__arrow--next"><i className="bi bi-chevron-right"></i></button>
                                    </div>

                                    <div className="splide__track pb-1">
                                        <ul className="splide__list">
                                            {courses.map((course, index) =>
                                                <li className="splide__slide" key={'course-key-'+index}>
                                                    <Link to="/lecture/details" className="app-card-link">
                                                        <div className="course-card">
                                                            <div className="course-card-media">
                                                                <img src={course.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                                <span className={ 'course-card-tag badge bg-'+badge[course.category]}>{course.category}</span>
                                                            </div>

                                                            <div className="course-card-info">
                                                                <div className="course-card-title">{course.title}</div>
                                                                
                                                                <div className="course-card-rating">
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <span>{course.rating} ({course.reviews})</span>
                                                                </div>

                                                                <div className="course-card-desc">{course.participants} Participants</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 text-center mt-5">
                                    <Link to="#" className="btn btn-gradient-primary btn-lg fw-bold mt-3" style={{ padding: '10px 45px' }}>View More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default LectureDetails;