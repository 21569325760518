import GLightbox from 'glightbox';
import React from 'react';

class Lightbox extends React.Component {
    constructor(props) {
        super(props);

        this.lightboxRef = React.createRef();
    }

    componentDidMount() {
        GLightbox();
    }

    render() {
      return (
        <>
            <a href={this.props.url} className={'glightbox ' + this.props.className}>
                {this.props.children}
            </a>
        </>
      );
    }
}

export default Lightbox;
