import React from 'react';
import Splide from '@splidejs/splide';
import ApiService from '../../services/Api.service';

class Slider extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.sliderRef = React.createRef();

        this.api = ApiService.route(this.props.api);

        this.state = {
            slider: null,
            items: [],
            skeleton: this.api && this.props.skeleton,
            loading: false,
            page: 1,
            hasMoreItems: false,
            renderedItems: null,
        };
    }

    willRender() {
        if (this.props.render) {
            this.setState({renderedItems: this.props.render(this.state.items, this)});
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.api) {
            this.loadDataInitially();
        } else {
            this.initSlider();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadDataInitially() {
        let result = await ApiService.get(
            this.api,
            Object.assign({page: this.state.page}, this.props.data)
        );

        if (! this._isMounted) {
            return;
        }

        this.setState({items: result.data});

        this.setState({hasMoreItems: result.pagination.hasMoreItems});

        this.setState({page: this.state.page + 1});

        this.willRender();

        this.setState({skeleton: false});

        this.initSlider();
    }

    async loadData() {
        let hasNextSlider = window.$(this.state.slider.root).find('.splide__slide.is-visible:last').next().length;

        if (hasNextSlider || this.state.loading || ! this.api) {
            return;
        }

        this.setState({loading: true});

        let result = await ApiService.get(
            this.api,
            Object.assign({page: this.state.page}, this.props.data)
        );

        if (! this._isMounted) {
            return;
        }

        this.setState({items: this.state.items.concat(result.data)});

        this.setState({hasMoreItems: result.pagination.hasMoreItems});

        this.willRender();

        this.setState({loading: false});

        this.setState({page: this.state.page + 1});

        setTimeout(() => {
            this.state.slider.refresh().go('>');
        }, 1)
    }

    initSlider() {
        if (! this.sliderRef.current) {
            return;
        }

        let $ = window.$;

        let instance = this;

        let options = Object.assign({
            type: 'slider',
            keyboard: false,
            perPage: 4,
            perMove: 4,
            pagination: false,
            speed: 1000,
            gap: '1rem',
        }, this.props.options);

        let slider = new Splide(this.sliderRef.current, options);

        slider.on('mounted', function() {
            let prev = slider.Components.Arrows.arrows.prev;

            let next = slider.Components.Arrows.arrows.next;

            slider.length > slider.options.perPage
                ? $(next).show()
                : $(next).hide();

            $(prev).hide();
        });

        slider.on('arrows:updated', function(prev, next, prevIndex, nextIndex) {
            prevIndex >= 0
                ? $(prev).fadeIn()
                : $(prev).fadeOut();

            nextIndex < 0 && ! instance.state.hasMoreItems
                ? $(next).fadeOut()
                : $(next).fadeIn();
        });

        slider.mount();

        this.setState({slider: slider});
    }

    render() {
        return (
            <>
                {this.state.skeleton ? this.props.skeleton :
                    <div className="splide" ref={this.sliderRef}>
                        <div className="splide__arrows">
                            <button className="splide__arrow splide__arrow--prev"><i className="bi bi-chevron-left"></i></button>
                            <button className="splide__arrow splide__arrow--next" onClick={() => this.loadData()}>
                                <i className={`bi ${this.state.loading ? 'bi-arrow-repeat spin' : 'bi-chevron-right'}`}></i>
                            </button>
                        </div>

                        <div className="splide__track">
                            <ul className="splide__list">
                                {this.state.renderedItems}
                                {this.props.children}
                            </ul>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Slider;
