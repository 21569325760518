import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import Layout from "../../components/Layout/Layout";

function Login() {
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push('student/dashboard');
    }

    return (
        <>
            <Layout header={false} footer={false}>
                <section className="login vh-100 bg-default">
                    <div className="text-center pt-5">
                        <Link to="/home">
                            <img src="/assets/images/logo-lg.png" alt="Logo" loading="lazy" />
                        </Link>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className="auth-card-wrap">
                            <div className="auth-card">
                                <div className="auth-card-title text-center pt-4">Log in</div>

                                <div className="px-4 mt-3">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <input type="email" className="form-control bg" name="email" id="email" placeholder="Email" />
                                            </div>

                                            <div className="col-12 mb-3">
                                                <input type="password" className="form-control bg" name="password" id="password" placeholder="Password" />
                                            </div>                                            
                                        </div>

                                        <div className="row">
                                            <div className="col-12 my-3" style={{ fontSize: '10px' }}>
                                                <label className="d-flex align-items-center" htmlFor="remember">
                                                    <input type="checkbox" className="me-2" name="remember" id="remember" /> Remember me later
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-center my-3">
                                                <button className="btn btn-primary btn-sm px-4">Log in</button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="pb-3" style={{ fontSize: '10px' }}>
                                        Don't have an account &nbsp; &nbsp;
                                        
                                        <Link to="/register">Sign Up</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
}

export default Login;