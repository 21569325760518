import Slider from "../../../components/Slider/Slider";
import SliderItem from "../../../components/Slider/Item";
import Gap from "../../../components/UI/Gap";

function Mentor() {
    return (
        <>
            <Gap height="80px" />

            <section className="mentor">
                <div className="container">
                    <div className="container-padding">
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="section-heading">Mentors recomendations</div>
                            </div>
                        </div> */}

                        <div className="row mt-3">
                            <Slider
                                api="mentor"
                                skeleton={<Skeleton />}
                                options={{perPage: 3, perMove: 3}}
                                render={(items, instance) => {
                                    return items.map((item, itemIndex) =>
                                        <SliderItem key={`slider-item-key-${itemIndex}`}>
                                            <div className="mentor-item">
                                                <div className="mentor-img">
                                                    <img src={item.image} alt="Mentor" loading="lazy" width="115" height="115" />
                                                </div>

                                                <div className="mentor-info">
                                                    <div className="mentor-name">{item.name}</div>
                                                    <div className="mentor-bio">{item.bio}</div>
                                                </div>
                                            </div>
                                        </SliderItem>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <Gap />
        </>
    );
}

function Skeleton() {
    return (
        <>
            {[...Array(3)].map((value, skeletonIndex) =>
                <div className="col-4" key={`skeleton-key-${skeletonIndex}`}>
                    <div className="app-skeleton app-skeleton-blink p-0">
                        <div className="d-flex">
                            <div className="app-skeleton-item app-skeleton-item-circle" style={{width: '100px', height: '100px'}}></div>

                            <div className="ms-3 mt-3">
                                <div className="app-skeleton-item mb-2" style={{width: '160px', height: '20px'}}></div>
                                <div className="app-skeleton-item" style={{width: '160px', height: '30px'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Mentor;
