import { useEffect } from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Splide from '@splidejs/splide';

import Blank from './pages/Blank';
import Home from './pages/Home/Home';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';

import SubjectDetails from './pages/Subject/Details';

import StudentDashboard from './pages/Student/Dashboard';
import StudentProfile from './pages/Student/Profile';

import CourseDetails from './pages/Course/Details';
import LectureDetails from './pages/Course/LectureDetails';
import LectureForm from './pages/Course/LectureForm';
import QuizeApply from './pages/Course/QuizeApply';

import TeacherLanding from './pages/Teacher/Landing';
import TeacherDashboard from './pages/Teacher/Dashboard';
import TeacherDetails from './pages/Teacher/Details';

import MentorLanding from './pages/Mentor/Landing';
import MentorDashboard from './pages/Mentor/Dashboard';

import InvestorBids from './pages/Investor/Bids';
import BidsForm from './pages/Investor/BidsForm';

import CareerList from './pages/Career/List';
import CareerDetails from './pages/Career/Details';
import CareerForm from './pages/Career/Form';

function App() {
    const $ = window.$;

    useEffect(() => {
        $('body').delegate('.side-menu-toggle', 'click', function () {
            $('.side-menu').toggleClass('open');
        });

        $('body').delegate('.tab-item', 'click', function () {
            $(this).parent().parent().find('.tab-item.active').removeClass('active');
            $(this).addClass('active');
        });

        var courseSliders = document.getElementsByClassName('course-splide');

        for (var i = 0, len = courseSliders.length; i < len; i++) {
            let courseSlider = new Splide(courseSliders[i], {
                type: 'slider',
                keyboard: false,
                perPage: 4,
                perMove: 4,
                pagination: false,
                speed: 1000,
                gap: '1.5rem',
            });

            courseSlider.on('mounted', function () {
                let prev = courseSlider.Components.Arrows.arrows.prev;
                let next = courseSlider.Components.Arrows.arrows.next;

                courseSlider.length > courseSlider.options.perPage
                    ? $(next).show()
                    : $(next).hide();

                $(prev).hide();
            });

            courseSlider.on('arrows:updated', function (prev, next, prevIndex, nextIndex) {
                prevIndex >= 0
                    ? $(prev).fadeIn()
                    : $(prev).fadeOut();

                nextIndex < 0
                    ? $(next).fadeOut()
                    : $(next).fadeIn();
            });

            courseSlider.mount();
        }

        $('.modal-backdrop').remove();

        $('body').css({'overflow-y': 'auto'});

        $('body').removeClass('modal-open')
    });

    return (
        <>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/blank" component={Blank} />
                <Route path="/home" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />

                <Route path="/subject/details" component={SubjectDetails} />

                <Route path="/student/dashboard" component={StudentDashboard} />
                <Route path="/student/profile" component={StudentProfile} />

                <Route path="/course/details" component={CourseDetails} />
                <Route path="/lecture/details" component={LectureDetails} />
                <Route path="/lecture/form" component={LectureForm} />
                <Route path="/course/quize-apply" component={QuizeApply} />

                <Route path="/career/list" component={CareerList} />
                <Route path="/career/details" component={CareerDetails} />
                <Route path="/career/form" component={CareerForm} />

                <Route path="/teacher" component={TeacherLanding} exact />
                <Route path="/teacher/dashboard" component={TeacherDashboard} />
                <Route path="/teacher/details" component={TeacherDetails} />

                <Route path="/mentor" component={MentorLanding} exact />
                <Route path="/mentor/dashboard" component={MentorDashboard} />

                <Route path="/investor/bids" component={InvestorBids} />
                <Route path="/bids-form" component={BidsForm} />
            </Switch>
        </>
    );
}

export default withRouter(App);
