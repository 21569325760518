import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';
import StudentJoinedChart from './Partials/StudentJoinedChart';

function Dashboard() {
    let courses = [
        {
            'title': 'Lorem ipsum, or lipsum as it is sometimes known',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/06.jpg',
        },
        {
            'title': 'Lorem ipsum, or lipsum as it is sometimes known',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/02.jpg',
        },
        {
            'title': 'Lorem ipsum, or lipsum as it is sometimes known',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/04.jpg',
        },
        {
            'title': 'Lorem ipsum, or lipsum as it is sometimes known',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/05.jpg',
        },
        {
            'title': 'Lorem ipsum, or lipsum as it is sometimes known',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/02.jpg',
        },
        {
            'title': 'Lorem ipsum, or lipsum as it is sometimes known',
            'sub_title': 'Subject Name',
            'cover': '/assets/images/card/04.jpg',
        }
    ];

    return (
        <>
            <Gap />

            <Layout user="teacher">
                <section className="dashboard-section">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-4">
                                    <div className="position-relative">
                                        <div className="d-flex justify-content-between align-items-center bg-white rounded shadow p-3">
                                            <div>
                                                <div className="app-text-md mb-3">Total Engagement</div>
                                                <div className="fs-4 fw-bold">135</div>
                                            </div>

                                            <div className="d-flex justify-content-center align-items-center rounded-circle bg-primary-300" style={{ width: '65px', height: '65px' }}>
                                                <i className="bi bi-person text-white fs-1"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="position-relative">
                                        <div className="d-flex justify-content-between align-items-center bg-white rounded shadow p-3">
                                            <div>
                                                <div className="app-text-md mb-3">Total Concepts</div>
                                                <div className="fs-4 fw-bold">8</div>
                                            </div>

                                            <div className="d-flex justify-content-center align-items-center rounded-circle bg-primary-300" style={{ width: '65px', height: '65px' }}>
                                                <img src="/assets/images/icon/40.svg" width="35" height="35" alt="Icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="position-relative">
                                        <div className="d-flex justify-content-between align-items-center bg-white rounded shadow p-3">
                                            <div>
                                                <div className="app-text-md">Create New Concept</div>
                                            </div>

                                            <div className="d-flex justify-content-center align-items-center rounded-circle bg-primary-300 my-1" style={{ width: '65px', height: '65px' }}>
                                                <span className="text-white fs-1">+</span>
                                            </div>
                                        </div>

                                        <Link to="/career/form" className="stretched-link"></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dashboard-section mt-5">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-8">
                                    <div className="position-relative">
                                        <div className="bg-white rounded shadow p-3">
                                            <div className="app-text-md fw-bold">Student Joined</div>

                                            <div>
                                                <StudentJoinedChart />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="position-relative">
                                        <div className="d-flex flex-column align-items-center bg-white rounded shadow p-3 py-4">
                                            <div className="d-flex justify-content-center align-items-center rounded-circle bg-primary-300" style={{ width: '65px', height: '65px' }}>
                                                <i className="bi bi-currency-dollar text-white fs-1"></i>
                                            </div>
                                            
                                            <div className="app-text-md mt-4 mb-2">Your Earning This Month</div>

                                            <div className="fs-1 text-primary fw-bold mt-3">$465</div>

                                            <div className="app-text-xs mt-4 mb-2">Check Your Financial Dashboard</div>

                                            <div className="pb-2">
                                                <Link to="#" className="btn btn-gradient-primary btn-lg fw-bold mt-2">View Earnings</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dashboard-section mt-5">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="fs-5 fw-bold">My Concepts</div>
                                </div>
                            </div>
                        
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav app-tabs mt-3">
                                        <li className="nav-item active">
                                            <a href="#active" className="nav-link active" data-bs-toggle="tab">Active</a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#active" className="nav-link" data-bs-toggle="tab">Unpublished</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="active">
                                            <div className="row mt-5">
                                                <div className="splide course-splide">
                                                    <div className="splide__arrows">
                                                        <button className="splide__arrow splide__arrow--prev"><i className="bi bi-chevron-left"></i></button>
                                                        <button className="splide__arrow splide__arrow--next"><i className="bi bi-chevron-right"></i></button>
                                                    </div>

                                                    <div className="splide__track pb-1">
                                                        <ul className="splide__list">
                                                            {courses.map((course, index) =>
                                                                <li className="splide__slide" key={'course-key-'+index}>
                                                                    <div className="course-card">
                                                                        <div className="course-card-media">
                                                                            <img src={course.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                                        </div>

                                                                        <div className="course-card-info">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div>
                                                                                    <div className="app-text-sm">{course.sub_title}</div>
                                                                                    <div className="app-text-md">{course.title}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex mt-3">
                                                                                <div className="app-block-xs rounded-circle bg-gray d-flex justify-content-center align-items-center overflow-hidden">
                                                                                    <img src="/assets/images/icon/41.svg" className="mt-1" width="15" height="15" alt="Icon" loading="lazy" />
                                                                                </div>

                                                                                <div className="app-block-xs rounded-circle bg-gray d-flex justify-content-center align-items-center overflow-hidden">
                                                                                    <img src="/assets/images/icon/41.svg" className="mt-1" width="15" height="15" alt="Icon" loading="lazy" />
                                                                                </div>

                                                                                <div className="app-block-xs rounded-circle bg-gray d-flex justify-content-center align-items-center overflow-hidden">
                                                                                    <img src="/assets/images/icon/41.svg" className="mt-1" width="15" height="15" alt="Icon" loading="lazy" />
                                                                                </div>

                                                                                <div className="app-block-xs rounded-circle bg-gray d-flex justify-content-center align-items-center overflow-hidden">
                                                                                    <img src="/assets/images/icon/41.svg" className="mt-1" width="15" height="15" alt="Icon" loading="lazy" />
                                                                                </div>

                                                                                <div className="app-block-xs rounded-circle bg-gray d-flex justify-content-center align-items-center overflow-hidden ms-1">
                                                                                    <span className="text-white d-inline-block pt-1" style={{ fontSize: 8 }}>33+</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <div className="text-muted" style={{ fontSize: 9 }}>Published Date - 03/01/2020</div>

                                                                                <div className="text-end">
                                                                                    <Link to="#" className="btn btn-sm btn-outline-dark py-1 px-2 opacity-50 rounded-1 app-text-sm">View</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dashboard-section mt-5">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="fs-5 fw-bold">Q & A</div>
                                </div>
                            </div>
                        
                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="rounded-3 bg-light-300 p-3">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="rounded-3 bg-white">
                                                    <div className="position-relative p-3">
                                                        <input type="text" className="form-control border-muted" />

                                                        <button type="button" className="border-0 bg-none position-absolute top-50 end-0 translate-middle-y">
                                                            <i className="bi bi-search text-muted me-3"></i>
                                                        </button>
                                                    </div>

                                                    <div className="overflow-auto" style={{ minHeight: 410, maxHeight: 410 }}>
                                                        <div className="bg-light-700 border-bottom p-3 py-4" style={{ borderLeft: '8px solid var(--app-primary)' }}>
                                                            <div className="fs-5">Concept Title</div>
                                                            <div className="app-text-sm opacity-75">Subject Name</div>
                                                        </div>

                                                        <div className="border-bottom p-3 py-4">
                                                            <div className="fs-5">Concept Title</div>
                                                            <div className="app-text-sm opacity-75">Subject Name</div>
                                                        </div>

                                                        <div className="border-bottom p-3 py-4">
                                                            <div className="fs-5">Concept Title</div>
                                                            <div className="app-text-sm opacity-75">Subject Name</div>
                                                        </div>

                                                        <div className="border-bottom p-3 py-4">
                                                            <div className="fs-5">Concept Title</div>
                                                            <div className="app-text-sm opacity-75">Subject Name</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div className="rounded bg-white">
                                                    <div className="overflow-auto" style={{ minHeight: 403, maxHeight: 403 }}>
                                                        <div className="d-flex p-3 pb-2 mb-3">
                                                            <div className="pe-3">
                                                                <img src="/assets/images/icon/18.svg" width="30" height="30" alt="User" loading="lazy" />
                                                            </div>

                                                            <div className="w-100">
                                                                <div className="app-text-md fw-bold">Student Name</div>
                                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                                    dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
                                                                    book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                                    
                                                                    <div className="app-text-md fw-bold mt-2">Reply</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex p-3 pb-2 mb-3">
                                                            <div className="pe-3">
                                                                <img src="/assets/images/icon/18.svg" width="30" height="30" alt="User" loading="lazy" />
                                                            </div>

                                                            <div className="w-100">
                                                                <div className="app-text-md fw-bold">Student Name</div>
                                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.                                                                    
                                                                    
                                                                    <div className="app-text-md fw-bold mt-2">Reply</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex p-3 pb-2">
                                                        <div className="pe-3 pt-1">
                                                            <img src="/assets/images/avatar/02.svg" width="30" height="30" alt="User" loading="lazy" />
                                                        </div>

                                                        <div className="w-100 position-relative">
                                                            <textarea className="textarea bg-light" rows="2" placeholder="Write a comment..."></textarea>

                                                            <button type="button" className="border-0 bg-none position-absolute top-50 end-0 translate-middle-y">
                                                                <img src="/assets/images/icon/42.svg" className="me-2 mb-2" width="20" height="20" alt="User" loading="lazy" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dashboard-section mt-5">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">                                
                                    <div className="bg-white rounded shadow pt-2">
                                        <div className="fs-5 fw-bold ps-3">All Lectures</div>

                                        <div className="mt-3">
                                            <div className="table-responsive">
                                                <table className="table align-middle">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">IMG.</th>
                                                            <th className="text-center">Lecture Title</th>
                                                            <th className="text-center">Published at</th>
                                                            <th className="text-center">Updated at</th>
                                                            <th className="text-center">Price</th>
                                                            <th className="text-center">Status</th>
                                                            <th className="text-end">Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {courses.map((course, index) =>
                                                            <tr key={'lecture-key-'+ index}>
                                                                <td className="text-center">
                                                                    <img src={course.cover} className="rounded-3" alt="Course" width="70" height="45" loading="lazy" />
                                                                </td>
                                                                <td className="text-center opacity-75">Lecture Name goes here 1  </td>
                                                                <td className="text-center opacity-75">01/01/2021</td>
                                                                <td className="text-center opacity-75">02/02/2021</td>
                                                                <td className="text-center opacity-75">$7.99</td>
                                                                <td className="text-center opacity-75">Active</td>
                                                                <td className="text-end">
                                                                    <Link to="#">
                                                                        <img src="/assets/images/icon/43.svg" className="me-3" width="15" height="15" alt="Icon" loading="lazy" />
                                                                    </Link>

                                                                    <Link to="#">
                                                                        <img src="/assets/images/icon/44.svg" width="15" height="15" alt="Icon" loading="lazy" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Link to="#" className="app-block-sm rounded-circle bg-primary-300 d-flex justify-content-center align-items-center">
                                            <img src="/assets/images/icon/38.svg" width="15" height="15" alt="Prev" loading="lazy" />
                                        </Link>

                                        <div className="mx-3">
                                            <span>1</span> - <span className="text-muted">7</span>
                                        </div>

                                        <Link to="#" className="app-block-sm rounded-circle bg-primary d-flex justify-content-center align-items-center">
                                            <img src="/assets/images/icon/39.svg" width="15" height="15" alt="Next" loading="lazy" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Dashboard;