import { Link } from 'react-router-dom';
import SliderItem from '../../components/Slider/Item';
import Slider from '../../components/Slider/Slider';

function CareerPathSlider() {
    return (
        <>
            <Slider options={{perPage: 2, perMove: 2}}>
                {[...Array(6)].map((value, index) =>
                    <SliderItem key={`path-key-${index}`}>
                        <div className="border rounded bg-white">
                            <div className="rounded bg-default text-center fs-5 fw-bold text-white py-4">
                                Become a Data Sciencetist at Google
                            </div>

                            <div className="px-2 py-3">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="app-text-sm mb-1">Created By</div>
                                        <div className="app-text-md fw-bold mb-1">Mentor Name</div>
                                        <div className="app-text-sm">Job Discription of Mentor</div>
                                    </div>

                                    <div>
                                        <Link to="/career/details" className="btn btn-sm btn-outline-primary app-text-sm px-3 rounded-3 w-100 mb-2">View Career Path</Link><br />
                                        <Link to="#" className="btn btn-sm btn-primary app-text-sm px-3 rounded-3 w-100">Apply For Certificate</Link>
                                    </div>
                                </div>

                                <div className="pt-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="w-100 pe-2">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '70%' }}></div>
                                            </div>
                                        </div>

                                        <div className="app-text-md text-end">70%</div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between text-center app-text-sm">
                                    <div className="w-100">Total<br />13</div>
                                    <div className="w-100">Completed<br />9</div>
                                    <div className="w-100">Remaning<br />4</div>
                                </div>
                            </div>
                        </div>
                    </SliderItem>
                )}
            </Slider>
        </>
    );
}

export default CareerPathSlider;
