import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function Details() {
    let courses = [
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Mathematic',
            'cover': '/assets/images/card/05.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Physics',
            'cover': '/assets/images/card/04.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Engineering',
            'cover': '/assets/images/card/02.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
    ];

    let badge = {'Mathematic': 'primary', 'Physics': 'success', 'Engineering': 'danger', 'Machine': 'info'};

    return (
        <>
            <Layout>
                <section className="details-content pt-5">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row g-4 pt-3">
                                <div className="col-3">
                                    <div className="p-3 rounded bg-light-300">
                                        <div className="text-center">
                                            <img src="/assets/images/avatar/04.svg" width="50" height="50" alt="Teacher" loading="lazy" />
                                            
                                            <div className="app-text-sm fw-bold mt-2">Josh William</div>
                                            
                                            <div className="d-flex justify-content-center align-items-center app-text-xs my-1">
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <span className="fw-bold ms-2">4.6 (4.6)</span>
                                            </div>

                                            <div className="app-text-xs my-3">Teacher discription will goes here</div>

                                            <div className="app-text-md fw-bold my-3">California, USA</div>
                                        </div>

                                        <div className="text-left">
                                            <div className="app-text-sm fw-bold my-3">Discription</div>
                                            
                                            <div className="app-text-xs">
                                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century.
                                            </div>
                                        </div>

                                        <div className="ps-1 pt-5">
                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/20.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">English</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/21.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">36 Recomandation</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/22.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">Stanford University</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/23.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">360 Lecture Hours</div>
                                            </div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <img src="/assets/images/icon/24.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                <div className="app-text-md fw-bold ms-3">Joined at 3/11/2021</div>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="app-text-sm fw-bold mb-3">Socials</div>

                                            <div className="pb-4 d-flex align-items-center">
                                                <Link to="#" className="d-flex justify-content-center align-items-center app-block-40 rounded-circle bg-gray me-3">
                                                    <img src="/assets/images/icon/26.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                </Link>

                                                <Link to="#" className="d-flex justify-content-center align-items-center app-block-40 rounded-circle bg-gray me-3">
                                                    <img src="/assets/images/icon/27.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                </Link>

                                                <Link to="#" className="d-flex justify-content-center align-items-center app-block-40 rounded-circle bg-gray me-3">
                                                    <img src="/assets/images/icon/28.svg" width="25" height="25" alt="Icon" loading="lazy" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-9">
                                    <div className="fs-5 fw-bold">Lectures</div>

                                    <div className="row mt-3">
                                        {courses.map((course, index) =>
                                            <div className="col-4" key={'all-course-key-'+index}>
                                                <Link to="/lecture/details" className="app-card-link">
                                                    <div className="course-card mb-5 shadow">
                                                        <div className="course-card-media">
                                                            <img src={course.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                            <span className={ 'course-card-tag badge bg-'+badge[course.category]}>{course.category}</span>
                                                        </div>

                                                        <div className="course-card-info">
                                                            <div className="course-card-title">{course.title}</div>
                                                            
                                                            <div className="course-card-rating">
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <span>{course.rating} ({course.reviews})</span>
                                                            </div>

                                                            <div className="course-card-desc">{course.participants} Participants</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <Link to="#" className="btn btn-gradient-primary btn-lg fw-bold" style={{ padding: '10px 45px' }}>View More</Link>
                                        </div>
                                    </div>

                                    <div className="fs-5 fw-bold mt-5 pb-4">Reviews and Ratings</div>

                                    <div>
                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/01.png" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                    dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
                                                    book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/04.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/05.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/01.png" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                    dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
                                                    book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex p-3 pb-2 mb-1 bg-light-300">
                                            <div className="pe-3">
                                                <img src="/assets/images/avatar/04.svg" width="30" height="30" alt="User" loading="lazy" />
                                            </div>

                                            <div className="w-100">
                                                <div className="app-text-md fw-bold">Student Name</div>

                                                <div className="d-flex align-items-center app-text-sm mb-2">
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-yellow me-1"></i>
                                                    <i className="bi bi-star-fill text-muted me-1"></i>
                                                </div>

                                                <div className="app-text-xs" style={{ lineHeight: 2 }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <div className="mt-5 pagination pagination-white d-flex justify-content-end">
                                                <Link to="#" className="pagination-item active">1</Link>
                                                <Link to="#" className="pagination-item">2</Link>
                                                <Link to="#" className="pagination-item">3</Link>
                                                <Link to="#" className="pagination-item">4</Link>
                                                <Link to="#" className="pagination-item">&gt;</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Details;
