import React from 'react';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { Range } from 'react-range';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function Bids() {
    const [range, setRange] = React.useState({ values: [0, 100] });

    const handleRange = values => {
        setRange({ values: values });
    };

    const delimiters = [188, 13];

    const [tags, setTags] = React.useState([
        { id: 'Tag 1', text: 'Tag 1' },
        { id: 'Tag 2', text: 'Tag 2' },
        { id: 'Tag 3', text: 'Tag 3' },
        { id: 'Tag 4', text: 'Tag 4' },
        { id: 'Tag 5', text: 'Tag 5' },
        { id: 'Tag 6', text: 'Tag 6' },
    ]);

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    return (
        <>
            <Layout user="investor">
                <Gap />

                <section className="subjects">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row g-5">
                                <div className="col-3">
                                    <div>
                                        <button className="btn btn-outline-primary py-2 px-4 bg-none">
                                            <img src="/assets/images/icon/34.svg" className="me-2" alt="Icon" loading="lazy" width="15" height="15" />
                                            Filter
                                        </button>
                                    </div>

                                    <div className="mt-4">
                                        <div className="p-3 bg-light-300">
                                            <div>
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="subject" className="form-label">Select Subject</label>
                                                    <select className="form-control form-select" name="subject" id="subject">
                                                        <option value=""></option>
                                                        <option value="Queatum Engineering">Queatum Engineering</option>
                                                        <option value="Machine Learning">Machine Learning</option>
                                                        <option value="Quantum Physics">Quantum Physics</option>
                                                        <option value="Math">Math</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <div className="app-text-sm fw-bold mb-1">Price Range</div>

                                                <div className="my-3">
                                                    {/* <input type="range" className="form-range" id="price_range" /> */}
                                                    <Range
                                                        step={1}
                                                        min={0}
                                                        max={100}
                                                        values={range.values}
                                                        onChange={handleRange}
                                                        allowOverlap={true}
                                                        draggableTrack={true}
                                                        renderTrack={({ props, children }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '3px',
                                                                    width: '100%',
                                                                    backgroundColor: '#C4C4C4'
                                                                }}
                                                            >
                                                                {children}
                                                            </div>
                                                        )}
                                                        renderThumb={({ props }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '10px',
                                                                    width: '10px',
                                                                    backgroundColor: '#fff',
                                                                    borderRadius: '50%',
                                                                    border: '1px solid #C4C4C4',
                                                                    outline: 'none',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className="app-text-xs text-center">${range.values[0]} - ${range.values[1]}</div>
                                            </div>

                                            <div className="mt-3">
                                                <div className="app-text-sm fw-bold mb-3">Tags</div>

                                                <div className="bg-white p-2">
                                                    <ReactTags
                                                        classNames={{
                                                            tag: 'app-text-xs text-black bg-light-500 m-1 p-1 px-5 position-relative',
                                                            tagInputField: 'form-control mt-1',
                                                        }}
                                                        tags={tags}
                                                        delimiters={delimiters}
                                                        handleDelete={handleDelete}
                                                        handleAddition={handleAddition}
                                                        placeholder={'Add Tags'}
                                                        allowDragDrop={false}
                                                        inline={false}
                                                        inputFieldPosition="bottom"
                                                        autocomplete={false}
                                                        autofocus={false}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <div className="app-text-sm fw-bold mb-3">Language</div>

                                                <div className="bg-white p-2">
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <button type="button" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3 border-0" data-bs-toggle="button" autoComplete="off">English</button>
                                                        <button type="button" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3 border-0" data-bs-toggle="button" autoComplete="off">Spanish</button>
                                                        <button type="button" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3 border-0" data-bs-toggle="button" autoComplete="off">বাংলা</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-9">
                                    <div className="text-end">
                                        <select className="btn btn-outline-primary py-2 px-4 form-select bg-transparent text-center" name="type" style={{ width: 120 }}>
                                            <option value="Popular">Popular</option>
                                            <option value="Latest">Latest</option>
                                            <option value="Rating">Rating</option>
                                            <option value="Duration">Duration</option>
                                        </select>
                                    </div>

                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="border">
                                                    {[...Array(10)].map((value, index) =>
                                                        <div className="bg-white border-bottom p-3 position-relative" key={'bid-key-' + index}>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="fw-bold fs-5 pt-1">I am looking for someone to build a HTML course</div>

                                                                <div className="text-end">
                                                                    <div>$250-300</div>
                                                                    <div className="app-text-sm">At <span className="text-primary">5%</span> Yearly Return</div>
                                                                </div>
                                                            </div>

                                                            <div className="col-10 py-2">
                                                                <div className="app-text-sm">
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                                                                    <Link to="#" className="fw-bold text-dark">......Read More</Link>
                                                                </div>
                                                            </div>

                                                            <div className="mb-3 mt-2">
                                                                <span className="app-text-xs text-black bg-light-500 me-2 p-1 px-4">HTML</span>
                                                                <span className="app-text-xs text-black bg-light-500 me-2 p-1 px-4">Front-end</span>
                                                                <span className="app-text-xs text-black bg-light-500 me-2 p-1 px-4">CSS</span>
                                                            </div>

                                                            <div className="text-muted app-text-xs">Deadline : March 15- March 22</div>

                                                            <Link to="#" className="stretched-link"></Link>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Gap />
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Bids;
