function SliderItem(props) {
    return (
        <>
            <li className="splide__slide">
                {props.children}
            </li>
        </>
    );
}

export default SliderItem;
