import { Link } from "react-router-dom";
import Lightbox from "../../../components/Lightbox";

function Video() {
    return (
        <>
            <section className="video">
                <div className="container">
                    <div className="container-padding">
                        <div className="row">
                            <div className="col-6">
                                <div className="video-sub-heading text-uppercase">Whatch out courses overview the understand the courses in details</div>
                                
                                <div className="video-heading text-uppercase my-3">Course <span className="text-primary">Overview</span></div>
                                
                                <div className="video-desc">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </div>

                                <Link to="#" className="btn btn-lg btn-gradient-primary text-uppercase mt-5 fw-bold fs-4">Watch Now</Link>
                            </div>

                            <div className="col-6">
                                <div className="video-thumb" style={{background: 'url(./assets/images/card/04.jpg)'}}>
                                    <Lightbox url="https://www.youtube.com/embed/MaKScf1UyT0" className="video-lightbox">
                                        <img src="/assets/images/icon/05.png" className="video-play" alt="Video" loading="lazy" width="100" height="100" />
                                    </Lightbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Video;
