import React from 'react';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { Range } from 'react-range';
import Layout from '../../components/Layout/Layout';
import Gap from '../../components/UI/Gap';

function Details() {
    let courses = [
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Mathematic',
            'cover': '/assets/images/card/05.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Physics',
            'cover': '/assets/images/card/04.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Engineering',
            'cover': '/assets/images/card/02.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Machine',
            'cover': '/assets/images/card/03.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Mathematic',
            'cover': '/assets/images/card/04.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        },
        {
            'title': 'Exploring Quantum\nPhysics',
            'category': 'Mathematic',
            'cover': '/assets/images/card/05.jpg',
            'rating': 4.6,
            'reviews': 128,
            'participants': 208
        }
    ];

    let badge = {'Mathematic': 'primary', 'Physics': 'success', 'Engineering': 'danger', 'Machine': 'info'};

    let subjects = [
        {name: 'Subject Name', image: '/assets/images/icon/03.png'},
        {name: 'Subject Name', image: '/assets/images/icon/01.png'},
        {name: 'Subject Name', image: '/assets/images/icon/31.png'},
        {name: 'Subject Name', image: '/assets/images/icon/32.png'},
        {name: 'Subject Name', image: '/assets/images/icon/04.png'},
        {name: 'Subject Name', image: '/assets/images/icon/02.png'},
        {name: 'Subject Name', image: '/assets/images/icon/33.png'},
    ];

    let ratings = [
        {name: '5.0'},
        {name: '4.5 & up'},
        {name: '4.0 & up'},
        {name: '3.0 & up'},
    ];

    const [range, setRange] = React.useState({ values: [0, 100] });

    const handleRange = values => {
        setRange({ values: values });
    };

    const delimiters = [188, 13];

    const [tags, setTags] = React.useState([
        { id: 'Tag 1', text: 'Tag 1' },
        { id: 'Tag 2', text: 'Tag 2' },
        { id: 'Tag 3', text: 'Tag 3' },
        { id: 'Tag 4', text: 'Tag 4' },
        { id: 'Tag 5', text: 'Tag 5' },
        { id: 'Tag 6', text: 'Tag 6' },
    ]);

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    return (
        <>
            <Layout>
                <Gap />

                <section className="featured-lectures">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-heading fw-bold">Featured Lectures</div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="splide course-splide">
                                    <div className="splide__arrows">
                                        <button className="splide__arrow splide__arrow--prev"><i className="bi bi-chevron-left"></i></button>
                                        <button className="splide__arrow splide__arrow--next"><i className="bi bi-chevron-right"></i></button>
                                    </div>

                                    <div className="splide__track pb-1">
                                        <ul className="splide__list">
                                            {courses.map((course, index) =>
                                                <li className="splide__slide" key={'course-key-'+index}>
                                                    <Link to="/lecture/details" className="app-card-link">
                                                        <div className="course-card">
                                                            <div className="course-card-media">
                                                                <img src={course.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                                <span className={ 'course-card-tag badge bg-'+badge[course.category]}>{course.category}</span>
                                                            </div>

                                                            <div className="course-card-info">
                                                                <div className="course-card-title">{course.title}</div>
                                                                
                                                                <div className="course-card-rating">
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <span>{course.rating} ({course.reviews})</span>
                                                                </div>

                                                                <div className="course-card-desc">{course.participants} Participants</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Gap />
                </section>

                <section className="subjects">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                {subjects.map((subject, index) =>
                                    <div className="max-content-width position-relative" key={'subject-key-'+index}>
                                        <div className="text-center pe-3">
                                            <div>
                                                <img src={subject.image} alt="Subject" loading="lazy" width="70" height="70" />
                                            </div>

                                            <div className="fw-bold text-muted text-uppercase mt-3 app-text-sm">Subject<br />Name</div>

                                            <Link to="#" className="stretched-link"></Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Gap />
                </section>

                <section className="subjects">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row g-5">
                                <div className="col-3">
                                    <div>
                                        <button className="btn btn-outline-primary py-2 px-4 bg-none">
                                            <img src="/assets/images/icon/34.svg" className="me-2" alt="Icon" loading="lazy" width="15" height="15" />
                                            Filter
                                        </button>
                                    </div>

                                    <div className="mt-4">
                                        <div className="p-3 bg-light-300">
                                            <div>
                                                <div className="app-text-sm fw-bold mb-3">Ratings</div>

                                                <div className="app-text-sm">
                                                    {ratings.map((rating, index) =>
                                                        <div className="d-flex justify-content-between align-items-center" key={'rating-key-' + index}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="rating" id={ 'rating-' + index } />
                                                                <label className="form-check-label app-text-sm" htmlFor={ 'rating-' + index }>{rating.name}</label>
                                                            </div>

                                                            <div className="d-flex align-items-center app-text-xs mb-2">
                                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                                <i className="bi bi-star-fill text-yellow"></i>
                                                            </div>
                                                        </div>                                                        
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <div className="app-text-sm fw-bold mb-1">Price Range</div>

                                                <div className="my-3">
                                                    {/* <input type="range" className="form-range" id="price_range" /> */}
                                                    <Range
                                                        step={1}
                                                        min={0}
                                                        max={100}
                                                        values={range.values}
                                                        onChange={handleRange}
                                                        allowOverlap={true}
                                                        draggableTrack={true}
                                                        renderTrack={({ props, children }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '3px',
                                                                    width: '100%',
                                                                    backgroundColor: '#C4C4C4'
                                                                }}
                                                            >
                                                                {children}
                                                            </div>
                                                        )}
                                                        renderThumb={({ props }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '10px',
                                                                    width: '10px',
                                                                    backgroundColor: '#fff',
                                                                    borderRadius: '50%',
                                                                    border: '1px solid #C4C4C4',
                                                                    outline: 'none',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className="app-text-xs text-center">${range.values[0]} - ${range.values[1]}</div>
                                            </div>

                                            <div className="mt-3">
                                                <div className="app-text-sm fw-bold mb-3">Tags</div>

                                                <div className="bg-white p-2">
                                                    <ReactTags
                                                        classNames={{
                                                            tag: 'app-text-xs text-black bg-light-500 m-1 p-1 px-5 position-relative',
                                                            tagInputField: 'form-control mt-1',
                                                        }}
                                                        tags={tags}
                                                        delimiters={delimiters}
                                                        handleDelete={handleDelete}
                                                        handleAddition={handleAddition}
                                                        placeholder={'Add Tags'}
                                                        allowDragDrop={false}
                                                        inline={false}
                                                        inputFieldPosition="bottom"
                                                        autocomplete={false}
                                                        autofocus={false}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <div className="app-text-sm fw-bold mb-3">Language</div>

                                                <div className="bg-white p-2">
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <button type="button" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3 border-0" data-bs-toggle="button" autoComplete="off">English</button>
                                                        <button type="button" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3 border-0" data-bs-toggle="button" autoComplete="off">Spanish</button>
                                                        <button type="button" className="app-text-xs text-black bg-light-500 m-1 p-1 px-3 border-0" data-bs-toggle="button" autoComplete="off">বাংলা</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-9">
                                    <div className="text-end">
                                        <select className="btn btn-outline-primary py-2 px-4 form-select bg-transparent" name="type" id="type" style={{ width: 120 }}>
                                            <option value="Popular">Popular</option>
                                            <option value="Latest">Latest</option>
                                            <option value="Rating">Rating</option>
                                            <option value="Duration">Duration</option>
                                        </select>
                                    </div>

                                    <div className="mt-4">
                                        <div className="row">
                                            {courses.map((course, index) =>
                                                <div className="col-4" key={'all-course-key-'+index}>
                                                    <Link to="/lecture/details" className="app-card-link">
                                                        <div className="course-card mb-5 shadow">
                                                            <div className="course-card-media">
                                                                <img src={course.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                                <span className={ 'course-card-tag badge bg-'+badge[course.category]}>{course.category}</span>
                                                            </div>

                                                            <div className="course-card-info">
                                                                <div className="course-card-title">{course.title}</div>
                                                                
                                                                <div className="course-card-rating">
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <span>{course.rating} ({course.reviews})</span>
                                                                </div>

                                                                <div className="course-card-desc">{course.participants} Participants</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )}

                                            {courses.map((course, index) =>
                                                <div className="col-4" key={'all-2-course-key-'+index}>
                                                    <Link to="/lecture/details" className="app-card-link">
                                                        <div className="course-card mb-5 shadow">
                                                            <div className="course-card-media">
                                                                <img src={course.cover} className="course-card-img" alt="Course" loading="lazy" width="100%" height="100%" />
                                                                <span className={ 'course-card-tag badge bg-'+badge[course.category]}>{course.category}</span>
                                                            </div>

                                                            <div className="course-card-info">
                                                                <div className="course-card-title">{course.title}</div>
                                                                
                                                                <div className="course-card-rating">
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <i className="bi bi-star-fill"></i>
                                                                    <span>{course.rating} ({course.reviews})</span>
                                                                </div>

                                                                <div className="course-card-desc">{course.participants} Participants</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="pagination pagination-white d-flex justify-content-end">
                                                    <Link to="#" className="pagination-item active">1</Link>
                                                    <Link to="#" className="pagination-item">2</Link>
                                                    <Link to="#" className="pagination-item">3</Link>
                                                    <Link to="#" className="pagination-item">4</Link>
                                                    <Link to="#" className="pagination-item">&gt;</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Gap />
                </section>

                <section className="teachers">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-heading fw-bold">Popular Lectures</div>
                                </div>
                            </div>
                                
                            <div className="row mt-3">
                                <div className="col-3">
                                    <div className="d-flex bg-white border rounded shadow position-relative p-2 mb-4">
                                        <div className="ps-1 pe-3">
                                            <img src="/assets/images/avatar/04.svg" width="50" height="50" alt="Teacher" loading="lazy" />
                                        </div>

                                        <div className="py-1">
                                            <div className="app-text-md fw-bold">Daniel Walter Scott</div>
                                            <div className="app-text-xs text-muted mb-1">Mathemetic</div>
                                            <div className="d-flex align-items-center app-text-xs mb-1">
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <span className="fw-bold ms-2">4.6 (4.6)</span>
                                            </div>
                                            <div className="app-text-xs fw-bold">28 Lectures</div>
                                        </div>

                                        <Link to="/teacher/details" className="stretched-link"></Link>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="d-flex bg-white border rounded shadow position-relative p-2 mb-4">
                                        <div className="ps-1 pe-3">
                                            <img src="/assets/images/avatar/01.png" width="50" height="50" alt="Teacher" loading="lazy" />
                                        </div>

                                        <div className="py-1">
                                            <div className="app-text-md fw-bold">Daniel Walter Scott</div>
                                            <div className="app-text-xs text-muted mb-1">Mathemetic</div>
                                            <div className="d-flex align-items-center app-text-xs mb-1">
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <span className="fw-bold ms-2">4.6 (4.6)</span>
                                            </div>
                                            <div className="app-text-xs fw-bold">28 Lectures</div>
                                        </div>

                                        <Link to="/teacher/details" className="stretched-link"></Link>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="d-flex bg-white border rounded shadow position-relative p-2 mb-4">
                                        <div className="ps-1 pe-3">
                                            <img src="/assets/images/avatar/03.svg" width="50" height="50" alt="Teacher" loading="lazy" />
                                        </div>

                                        <div className="py-1">
                                            <div className="app-text-md fw-bold">Daniel Walter Scott</div>
                                            <div className="app-text-xs text-muted mb-1">Mathemetic</div>
                                            <div className="d-flex align-items-center app-text-xs mb-1">
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <span className="fw-bold ms-2">4.6 (4.6)</span>
                                            </div>
                                            <div className="app-text-xs fw-bold">28 Lectures</div>
                                        </div>

                                        <Link to="/teacher/details" className="stretched-link"></Link>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="d-flex bg-white border rounded shadow position-relative p-2 mb-4">
                                        <div className="ps-1 pe-3">
                                            <img src="/assets/images/avatar/05.svg" width="50" height="50" alt="Teacher" loading="lazy" />
                                        </div>

                                        <div className="py-1">
                                            <div className="app-text-md fw-bold">Daniel Walter Scott</div>
                                            <div className="app-text-xs text-muted mb-1">Mathemetic</div>
                                            <div className="d-flex align-items-center app-text-xs mb-1">
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <i className="bi bi-star-fill text-yellow me-1"></i>
                                                <span className="fw-bold ms-2">4.6 (4.6)</span>
                                            </div>
                                            <div className="app-text-xs fw-bold">28 Lectures</div>
                                        </div>

                                        <Link to="/teacher/details" className="stretched-link"></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Gap />
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Details;