import { Link } from 'react-router-dom';

function SideMenu(props) {
    return (
        <div className="side-menu">
            <div className="side-menu-header">&nbsp;</div>

            <div className="side-menu-body">
                <ul className="side-menu-content">
                    <li className="side-menu-item active">
                        <Link to="#" className="side-menu-link">Explore</Link>
                    </li>

                    <li className="side-menu-item">
                        <Link to="/student/dashboard" className="side-menu-link">Student Dashboard</Link>
                    </li>

                    <li className="side-menu-item">
                        <Link to="/student/profile" className="side-menu-link">Student Profile</Link>
                    </li>

                    <li className="side-menu-item">
                        <Link to="#" className="side-menu-link">Membership</Link>
                    </li>

                    <li className="side-menu-item">
                        <Link to="#" className="side-menu-link">Make a deposit</Link>
                    </li>

                    <li className="side-menu-item">
                        <Link to="#" className="side-menu-link">Support</Link>
                    </li>
                </ul>
            </div>

            {props.user &&
                <div className="side-menu-footer text-center">
                    <div className="d-flex flex-column align-items-center">
                        <div className="side-menu-user-icon mb-2">
                            <img src="/assets/images/avatar/01.png" alt="User" />
                        </div>

                        <span className="app-text-sm fs-6 fw-bold mb-3">Jonathan W.</span>
                    </div>

                    <Link to="#" className="btn btn-sm px-3 btn-primary">View Profile</Link><br />
                    <Link to="/" className="btn btn-link text-decoration-none">Log out</Link>
                </div>
            }

            {! props.user &&
                <div className="side-menu-footer text-center">
                    <Link to="/register" className="btn btn-sm px-3 btn-primary">Join Now</Link><br />
                    <Link to="/login" className="btn btn-link text-decoration-none">Login</Link>
                </div>
            }
        </div>
    );
}

export default SideMenu;
