import Layout from '../components/Layout/Layout';
import Gap from '../components/UI/Gap';

function Blank() {
    return (
        <>
            <Layout>
                <Gap />

                <section className="page-content">
                    <div className="container">
                        <div className="container-padding">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Blank Page</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Gap height="80px" />
            </Layout>
        </>
    );
}

export default Blank;
